import React, { ComponentType, useEffect, useState } from "react";

function withRenderOnResize<T>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const MAX_MOBILE_WIDTH = 1125;

    const [shouldUseMobileLayout, setShouldUseMobileLayout] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setShouldUseMobileLayout(window.innerWidth <= MAX_MOBILE_WIDTH);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    return (
      <WrappedComponent
        {...{
          ...props,
          shouldUseMobileLayout,
        }}
      />
    );
  };
}

export default withRenderOnResize;
