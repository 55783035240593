import React from "react";
import LazyLoad from "react-lazyload";

import { TableColumn, SortColumn } from "./types";

import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

import "./Table.scss";

const Table = ({
  columns,
  sortColumn,
  onSort,
  onRowSelected,
  data,
}: {
  columns: TableColumn[];
  sortColumn: SortColumn;
  onSort: (sortColumn: SortColumn) => void;
  onRowSelected?: (o: any) => void;
  data: any;
}) => {
  const handleClick = (item: any) => {
    if (onRowSelected) {
      onRowSelected(item);
    }
  };
  return (
    <LazyLoad>
      <table className="table">
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
        <TableBody columns={columns} data={data} onRowSelected={handleClick} />
      </table>
    </LazyLoad>
  );
};

export default Table;
