import { PromotionState } from "../promotion";
const isProductiom =
  process.env.REACT_APP_BRAINTREE_ENVIRONMENT === "production";

enum Links {
  STANDARD_OPEN = "https://app.adjust.com/3soq2p3?deeplink=moshitwilightapp%3A%2F%2Floginform&fallback=https%3A%2F%2Fwww.moshikids.com&redirect_android=https%3A%2F%2Fapp.adjust.com/j7xslqx&redirect_ios=https%3A%2F%2Fapp.adjust.com%2Fki9hqm4",
  STANDARD_IOS = "https://app.adjust.com/ki9hqm4?deeplink=moshitwilightapp%3A%2F%2Floginform&fallback=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1306719339",
  STANDARD_GOOGLE = "https://app.adjust.com/j7xslqx?deeplink=moshitwilightapp%3A%2F%2Floginform&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.mindcandy.sleepstories",
  STANDARD_AMAZON = "https://app.adjust.com/of3f04o?deeplink=moshitwilightapp%3A%2F%2Floginform&fallback=https%3A%2F%2Fwww.amazon.co.uk%2FMoshi-Twilight-Sleep-Stories-Bedtime%2Fdp%2FB07BY1XV8N",
  MOBILE_DOWNLOAD = "https://links.moshisleep.com/loginform",
  MOBILE_DOWNLOAD_STA = "https://sta-links.moshisleep.com/loginform",
  GOUSTO = "https://app.adjust.com/9781lkj?label=Gousto-partnership&deeplink=moshitwilightapp%3A%2F%2Floginform&fallback=https%3A%2F%2Fwww.moshikids.com%2F&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.mindcandy.sleepstories%26hl%3Den_GB&redirect_ios=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fmoshi-twilight-sleep-stories%2Fid1306719339%3Fmt%3D8",
}

export interface ConfirmationDownloads {
  installedLink: string;
  iosLink: string;
  googleLink: string;
  amazonLink: string;
  mobileDownloadLink: string;
}

export function getStandardLinks() {
  return {
    installedLink: Links.STANDARD_OPEN,
    iosLink: Links.STANDARD_IOS,
    googleLink: Links.STANDARD_GOOGLE,
    amazonLink: Links.STANDARD_AMAZON,
    mobileDownloadLink: isProductiom
      ? Links.MOBILE_DOWNLOAD
      : Links.MOBILE_DOWNLOAD_STA,
  };
}

export function getConfirmationDownloadsByPromotion(
  promotion?: PromotionState
): ConfirmationDownloads {
  if (promotion && promotion.partner) {
    if (promotion.partner.partnerDisplayName.includes("Gousto")) {
      return {
        installedLink: Links.GOUSTO,
        iosLink: Links.GOUSTO,
        googleLink: Links.GOUSTO,
        amazonLink: Links.GOUSTO,
        mobileDownloadLink: Links.GOUSTO,
      };
    }
  }
  return getStandardLinks();
}
