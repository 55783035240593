import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";

import { ApplicationState } from "clients/store";
import * as contentActions from "clients/store/content/actions";
import * as pagesActions from "shared/store/pages/actions";
import { CATEGORY_IDS } from "../../../store/content/constants";

import { events } from "clients/helpers/logger";
import { StoryData } from "clients/store/content";

import MenuTable from "./table/MenuTable";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import ContentFilter from "./filter/ContentFilter";
import withSchoolOnlyAccess from "../../higherOrderComponents/schools/withSchoolOnlyAccess";
import withDesktopOnlyView from "../../higherOrderComponents/layout/withDesktopOnlyView";

import "./Menu.scss";

declare global {
  interface Window {
    Iterate: any;
  }
}

const Menu = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(contentActions.contentFetchAction());
  }, [dispatch]);

  const content = useSelector((state: ApplicationState) => state.content);
  const pagesState = useSelector((state: ApplicationState) => state.pages);

  let initialSelectedCategory = CATEGORY_IDS.ALL;
  if (pagesState[location.pathname]) {
    if (pagesState[location.pathname].filterData) {
      initialSelectedCategory =
        pagesState[location.pathname].filterData!.selectedFilterId;
    }
  }
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    initialSelectedCategory
  );
  const [filteredStoryData, setFilteredStoryData] = useState<StoryData[]>();
  const [selectedSelTag, setSelectedSelTag] = useState<string>();
  const [selectedTheme, setSelectedTheme] = useState<string>();
  useEffect(() => {
    if (content && content.data) {
      const category = content.data.categoryData.find(
        (categoryData) => categoryData.id === selectedCategoryId
      );
      if (category) {
        const storyData = category.storyIds.reduce(
          (accumulator: StoryData[], storyId) => {
            const story = content.data!.storyData.find(
              (item) => item.id === storyId
            );
            if (story) {
              accumulator.push(story);
            }
            return accumulator;
          },
          []
        );
        if (selectedSelTag) {
          setFilteredStoryData(
            storyData.filter((story) => story.selTag === selectedSelTag)
          );
        }
        if (selectedTheme) {
          setFilteredStoryData(
            storyData.filter((story) => story.themeName === selectedTheme)
          );
        }
        if (!selectedSelTag && !selectedTheme) {
          setFilteredStoryData(storyData);
        }
      }
    }
  }, [content, selectedCategoryId, selectedSelTag, selectedTheme]);

  const onCategorySelected = (id: string) => {
    setSelectedCategoryId(id);
    savePageState(id, selectedSelTag);
  };

  const onFilterApplied = (id: string, filterType?: string) => {
    if (filterType) {
      if (filterType === "sel") {
        setSelectedSelTag(id);
        savePageState(selectedCategoryId, id);
      } else if (filterType === "theme") {
        setSelectedTheme(id);
        savePageState(selectedCategoryId, id);
      }
    }
  };

  const onStorySelected = (storyId: string) => {
    savePageState(selectedCategoryId, selectedSelTag);
    if (filteredStoryData) {
      const storyData = filteredStoryData.find((story) => story.id === storyId);
      if (storyData) {
        events.song.selected(storyData, selectedCategoryId, selectedSelTag);
      }
    }
    let storyUrl = `play/${storyId}?categoryId=${selectedCategoryId}`;
    if (selectedSelTag) {
      storyUrl += `&selTagId=${selectedSelTag}`;
    }
    history.push(storyUrl);
  };

  useEffect(() => {
    if (filteredStoryData && location.pathname) {
      if (history.action === "POP") {
        if (pagesState[location.pathname]) {
          setTimeout(() => {
            window.scrollTo(
              0,
              pagesState[location.pathname].scrollData.yPosition
            );
          });
          if (pagesState[location.pathname].filterData) {
            setSelectedCategoryId(
              pagesState[location.pathname].filterData!.selectedFilterId
            );
            setSelectedSelTag(
              pagesState[location.pathname].filterData?.selectedSubFilterId
            );
          }
        }
      }
    }
  }, [pagesState, history, location, filteredStoryData]);

  const savePageState = (
    categoryId: string,
    selTagId?: string,
    narratorId?: string
  ) => {
    const pageState = {
      [location.pathname]: {
        scrollData: {
          yPosition: window.pageYOffset,
          xPosition: window.pageXOffset,
        },
        filterData: {
          selectedFilterId: categoryId,
          selectedSubFilterId: selTagId,
          selectedNarratorFilterId: narratorId,
        },
      },
    };
    dispatch(pagesActions.pagesSetAction({ ...pagesState, ...pageState }));
  };

  if (content.isLoading || !content.data || !filteredStoryData) {
    return (
      <div className="app-body center player-menu">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="app-body center player-menu">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">Moshi for Schools</h1>
        </div>
        <div className="player-menu-table">
          <ContentFilter
            selTagData={content.data.selTags}
            themeData={content.data.themes}
            categoryData={content.data.categoryData}
            onFilterChange={onFilterApplied}
            onCategoryChange={onCategorySelected}
            initialSelectedCategory={initialSelectedCategory}
          />
          <MenuTable
            baseUrl={content.data.baseUrl}
            filteredStoryData={filteredStoryData}
            onRowSelected={onStorySelected}
            category={selectedCategoryId}
            favorites={Array.from(content.data.favorites)}
          />
        </div>
      </div>
    </div>
  );
};

export default withSchoolOnlyAccess(withDesktopOnlyView(Menu));
