import React, { useState } from "react";
import withRenderOnResize from "../higherOrderComponents/layout/withRenderOnResize";
import HeaderLogo from "./shared/HeaderLogo";
import NavigationMenu from "./desktop/NavigationMenu";
import NavigationMobile from "./mobile/NavigationMobile";
import "./Header.scss";

interface Props {
  shouldUseMobileLayout?: boolean;
}
const Header = ({ shouldUseMobileLayout }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const onMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <nav className={"navigation" + (menuOpen ? " open" : "")} role="navigation">
      <div className={"navigation-content" + (menuOpen ? " open" : "")}>
        <HeaderLogo menuOpen={menuOpen} />
        {shouldUseMobileLayout ? (
          <NavigationMobile onMenuToggle={onMenuToggle} menuOpen={menuOpen} />
        ) : (
          <NavigationMenu />
        )}
      </div>
    </nav>
  );
};

export default withRenderOnResize(Header);
