import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { authLogoutAction } from "../../../../shared/store/auth";

import { mobileNavigationMenuItems, mobileFooterMenuItems } from "../constants";
import NavigationActionsMobile from "./NavigationActionsMobile";
import NavigationButtonMobile from "./NavigationButtonMobile";
import NavigationMenuMobile from "./NavigationMenuMobile";
import NavigationFooterMobile from "./NavigationFooterMobile";

import "./NavigationMobile.scss";
import { events } from "../../../helpers/logger";

interface Props {
  onMenuToggle: () => void;
  menuOpen: boolean;
}

const NavigationMobile = ({ onMenuToggle, menuOpen }: Props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: ApplicationState) => state.auth);
  const callLogout = () => {
    dispatch(authLogoutAction());
    events.logout.success();
    if (menuOpen) {
      onMenuToggle();
    }
  };

  return (
    <React.Fragment>
      <NavigationActionsMobile
        isLoggedIn={auth.isLoggedIn}
        callLogout={callLogout}
        closeMenu={onMenuToggle}
        menuOpen={menuOpen}
      />
      <NavigationButtonMobile onMenuToggle={onMenuToggle} menuOpen={menuOpen} />
      <div className={"navigation-mobile-wrapper" + (menuOpen ? " open" : "")}>
        <NavigationMenuMobile
          menuItems={mobileNavigationMenuItems}
          isLoggedIn={auth.isLoggedIn}
          closeMenu={onMenuToggle}
        />
        <NavigationFooterMobile menuItems={mobileFooterMenuItems} />
      </div>
    </React.Fragment>
  );
};

export default NavigationMobile;
