import React from "react";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import { Path } from "clients/store/path/constants";
import { IAuthLogin, Schema } from "shared/store/auth/api";

import FieldError from "../elements/fieldError";
import { NavLink } from "react-router-dom";
import FieldButton from "../elements/FieldButton";
import LoginLinks from "../../../../clients/components/blocks/componentAfter/LoginLinks";

export interface LoginFormProps {
  login: (u: IAuthLogin) => Promise<any>;
  fieldType: string;
}

const LoginFormTemplate = (props: LoginFormProps & FormikProps<IAuthLogin>) => {
  const { errors, touched, isSubmitting, fieldType } = props;

  return (
    <Form>
      <div className="form-group" onFocus={() => {}}>
        <Field
          id="username"
          name="username"
          type={fieldType}
          className={
            errors.username && touched.username
              ? "form-control email with-error"
              : "form-control email"
          }
          maxLength={128}
          placeholder="Email Address"
        />
        {errors.username && touched.username && (
          <FieldError message={errors.username} />
        )}
      </div>

      <div className="form-group mb-3">
        <Field
          name="password"
          type="password"
          className={
            errors.password && touched.password
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={128}
          placeholder="Password"
        />
        {errors.password && touched.password ? (
          <FieldError message={errors.password} />
        ) : (
          <div className="info-message">Min 8 characters and 1 number</div>
        )}
      </div>
      <div className="text-center regular font-bold mb-3">
        No account?
        <NavLink to={Path.SIGNUP} className="nav-link small font-bold">
          Sign up
        </NavLink>
      </div>
      <FieldButton
        text={"Login"}
        withLoading={isSubmitting}
        extra={<LoginLinks />}
      />
    </Form>
  );
};

const LoginForm = withFormik<LoginFormProps, IAuthLogin>({
  displayName: "LoginForm",
  handleSubmit: (
    values: IAuthLogin,
    formikBag: FormikBag<LoginFormProps, IAuthLogin>
  ) => {
    formikBag.props
      .login({ ...values } as IAuthLogin)
      .catch((e) => {
        /**/
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: () => {
    return {
      username: "",
      password: "",
    };
  },
  validationSchema: Schema,
})(LoginFormTemplate);

export default LoginForm;
