import {
  CategoryData,
  ContentActionTypes as actions,
  ContentApiResponse,
  ContentApiFavoritesResponse,
  SeriesData,
  StoryData,
} from "./types";
import { CATEGORY_IDS, CATEGORY_MAPPINGS, CONTENT_TYPES } from "./constants";
import { call, put, takeLatest } from "redux-saga/effects";
import { captureException } from "clients/helpers/logger";
import { contentSetAction } from "./actions";
import ContentApi from "./api";

function* contentFetch() {
  yield put(contentSetAction({ isLoading: true }));
  try {
    const contentApiData: ContentApiResponse = yield call(
      ContentApi.getContent
    );
    const favoritesApiData: ContentApiFavoritesResponse = yield call(
      ContentApi.getFavourites
    );

    const categoryData = mergeDuplicatedCategories(
      getCategoryDataFromApiResponse(contentApiData)
    );
    const categoryOrder = [
      CATEGORY_IDS.MOMENTS,
      CATEGORY_IDS.MEDITATIONS,
      CATEGORY_IDS.STORIES,
      CATEGORY_IDS.MUSIC,
      CATEGORY_IDS.SOUNDS,
      CATEGORY_IDS.SERIES,
      CATEGORY_IDS.BREATHE,
      CATEGORY_IDS.LULLABY,
    ];

    // Create an object containing the category orders indexed by id
    const order: { [key: string]: number } = categoryOrder.reduce(
      (
        accumulator: Record<string, number>,
        key: string,
        currentIndex: number
      ) => {
        return Object.assign(accumulator, { [key]: currentIndex });
      },
      {}
    );
    const storyData = contentApiData.storyData;
    categoryData.sort((a, b) => order[a.id] - order[b.id]);
    categoryData.push(
      createSeriesCategory(storyData, contentApiData.seriesData)
    );
    categoryData.unshift({
      id: "favorites",
      name: "Favorites",
      storyIds: favoritesApiData.storyIds,
    });
    categoryData.unshift(createAllCategory(storyData));

    const selTags = loadSelTagsFromStories(storyData);
    const themes = loadThemesFromStories(storyData);
    const data = {
      data: {
        baseUrl: contentApiData.storiesBaseUrl,
        storyData,
        categoryData: mapSpecialCategoryStoriesToCategory(
          categoryData,
          storyData
        ),
        seriesData: contentApiData.seriesData,
        selTags,
        themes,
        favorites: new Set(favoritesApiData.storyIds),
      },
    };
    yield put(contentSetAction({ isLoading: false, ...data }));
  } catch (e) {
    captureException(e, { category: "content-fetch" });
  }
}

export function* contentSagas() {
  yield takeLatest(actions.CONTENT_FETCH, contentFetch);
}

function mergeDuplicatedCategories(
  categoryData: CategoryData[]
): CategoryData[] {
  const filteredCategoryData = categoryData.map((category) => {
    const entries = categoryData.filter((f) => {
      return f.id === category.id;
    });
    if (entries.length > 1) {
      return {
        id: category.id,
        name: category.name,
        storyIds: Array.from(
          new Set(entries[0].storyIds.concat(entries[1].storyIds))
        ),
      };
    }
    return entries[0];
  });
  return removeDuplicateDataInCategories(filteredCategoryData);
}

function getCategoryDataFromApiResponse(
  contentApiData: ContentApiResponse
): CategoryData[] {
  const filteredScreens = [
    ...contentApiData.filterData[0].filteredScreens,
    ...contentApiData.filterData[1].filteredScreens,
  ];
  const seriesScreen = filteredScreens.find(
    (filteredScreen) => filteredScreen.id === CATEGORY_IDS.SERIES
  );
  if (seriesScreen) {
    filteredScreens.splice(filteredScreens.indexOf(seriesScreen), 1);
  }
  return ([] as CategoryData[]).concat(
    ...filteredScreens.map((screen) => {
      const allViewForScreen = screen.screenViews.find((view) =>
        view.name.match(/all/i)
      );
      return {
        id: screen.id,
        name: screen.name,
        storyIds: allViewForScreen!.storyIds,
      };
    })
  );
}

function removeDuplicateDataInCategories(categoryData: CategoryData[]) {
  return categoryData.filter((category, index) => {
    return index === categoryData.findIndex((obj) => obj.id === category.id);
  });
}

function createSeriesCategory(
  storyData: StoryData[],
  seriesData: SeriesData[]
): CategoryData {
  let seriesCategory = {
    id: CATEGORY_IDS.SERIES,
    name: "Series",
    storyIds: [] as string[],
  };
  for (const series of seriesData) {
    const localTime = new Date();
    let episode = 1;
    for (const storyId of series.storyIds) {
      const story = storyData.find((data) => data.id === storyId);
      if (
        story &&
        story.releaseDateMillis &&
        story.releaseDateMillis < localTime.getTime()
      ) {
        story.seriesName = series.name;
        story.episode = episode;
        seriesCategory.storyIds.push(storyId);
        episode += 1;
      }
    }
  }

  return seriesCategory;
}

function createAllCategory(storyData: StoryData[]): CategoryData {
  const filteredData = storyData.filter(
    (story) => story.contentType !== CONTENT_TYPES.SERIES
  );
  const storyIds = Array.from(
    new Set(
      filteredData.map((story) => {
        return story.id;
      })
    )
  );
  return { id: "all", name: "All", storyIds };
}

function loadSelTagsFromStories(storyData: StoryData[]): Set<string> {
  const selTags = new Set<string>();

  for (const story of storyData) {
    if (story.selTag) {
      selTags.add(story.selTag);
    }
  }
  return selTags;
}

function loadThemesFromStories(storyData: StoryData[]): Set<string> {
  const themes = new Set<string>();

  for (const story of storyData) {
    if (story.themeName) {
      themes.add(story.themeName);
    }
  }
  return themes;
}

function mapSpecialCategoryStoriesToCategory(
  categoryData: CategoryData[],
  storyData: StoryData[]
): CategoryData[] {
  for (const category of categoryData) {
    const contentTypeToMap = CATEGORY_MAPPINGS[category.id];
    if (contentTypeToMap) {
      const storyIdsToAdd = storyData.reduce(
        (accumulator: string[], currentValue: StoryData | any) => {
          if (currentValue.contentType === contentTypeToMap) {
            accumulator.push(currentValue.id);
          }
          return accumulator;
        },
        []
      );
      category.storyIds.push(...storyIdsToAdd);
    }
  }
  return categoryData;
}
