import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { getStepsByPromotion } from "../../store/promotion/constants";
import ProgressBar from "./ProgressBar";

import "./FlowIndicator.scss";

const FlowIndicator = ({
  stepName,
  step,
  stepCount = 3,
}: {
  stepName: string;
  step: number;
  stepCount?: number;
}) => {
  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const [totalSteps, setTotalSteps] = useState(stepCount);
  useEffect(() => {
    if (promotion.data) {
      if (stepCount) {
        setTotalSteps(stepCount);
      } else {
        setTotalSteps(getStepsByPromotion(promotion.data.promotionSlug));
      }
    }
  }, [stepCount, promotion]);

  return (
    <div className="text-center">
      <div className="flow-indicator">
        <p className="step-number">
          Step {step} of {totalSteps} - {stepName}
        </p>
      </div>
      <ProgressBar step={step} totalSteps={totalSteps} />
    </div>
  );
};

export default FlowIndicator;
