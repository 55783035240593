import {
  CheckSchoolCodeResponse,
  PartnerInfo,
  PromoCodeRedeemResponse,
  Promotion,
  ReferralInfo,
} from "./types";
import { AuthState } from "../../../shared/store/auth";

import { ApiError } from "shared/helpers/errors";

import Api from "clients/helpers/api";

/**
 * We dont want the user to know what's going on
 */
export class PromotionNotFound extends ApiError {}

export async function getPromotion(
  picked: string | undefined,
  country: string | undefined
): Promise<Promotion> {
  const queryStringParameters: any = {
    promotion: picked,
  };

  if (country) {
    queryStringParameters.country = country.toUpperCase();
  }

  try {
    const response = await Api.get(
      "/braintree/promotion",
      queryStringParameters
    );
    return {
      // todo, do some data check here
      ...response,
    };
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.code === "promotion-not-found") {
        throw new PromotionNotFound(error);
      }
    }
    throw error;
  }
}

export async function getPartnerInfo(partner: string): Promise<PartnerInfo> {
  return await Api.get(
    `partners/${partner}?refererHeader=${document.referrer}`
  );
}

export async function getReferralInfo(code: string): Promise<ReferralInfo> {
  const response = await Api.post("promo/unregistered-redeem", {
    promoCode: code,
  });
  return response.referralCodeData;
}
export async function checkSchoolPromoCode(
  promoCode: string,
  pathName: string
): Promise<CheckSchoolCodeResponse> {
  return await Api.get("/schools/promo", { promoCode, pathName });
}

export async function redeemPromoCode(
  auth: AuthState,
  promoCode: string,
  pathName: string,
  shouldRedeem?: boolean
): Promise<PromoCodeRedeemResponse> {
  let response = null;
  if (auth.isLoggedIn) {
    response = await redeemPromoCodeConfirmedUser(
      promoCode,
      pathName,
      shouldRedeem
    );
  } else {
    // this should never happen
    throw new Error("You need to be signed in to redeem a promo code");
  }
  return response;
}

async function redeemPromoCodeConfirmedUser(
  promoCode: string,
  pathName: string,
  shouldRedeem?: boolean
): Promise<PromoCodeRedeemResponse | any> {
  return await Api.post("/promo/redeem", {
    promoCode,
    shouldRedeem,
    originUrlSubPath: pathName,
  });
}
