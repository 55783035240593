import { ContentApiResponse, ContentApiFavoritesResponse } from "./types";

import Api from "clients/helpers/api";

export async function getContent(): Promise<ContentApiResponse> {
  try {
    const response = await Api.get(
      "/content/auth",
      {},
      {},
      1000 * 60 * 60 * 24
    );
    return { ...response };
  } catch (e) {
    throw e;
  }
}

export async function getFavorites(): Promise<ContentApiFavoritesResponse> {
  try {
    return await Api.get("/content/favorites");
  } catch (e) {
    throw e;
  }
}

export async function putFavorites(
  storyId: string
): Promise<ContentApiFavoritesResponse> {
  try {
    return await Api.put("/content/favorites", { storyId });
  } catch (e) {
    throw e;
  }
}

export default { getContent, getFavourites: getFavorites };
