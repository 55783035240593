import React, { Component } from "react";
import { HostedFieldsTokenizePayload } from "braintree-web";
import { toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";

import "./Payment.scss";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ProfileState } from "../../store/profile";
import { braintreeUpdatePayment } from "../../store/profile/api/braintree";
import { Labels, PromotionState } from "../../store/promotion";
import { events } from "../../helpers/logger";
import { Path } from "../../store/path/constants";

interface PaymentState {
  isLoading: boolean; // toggle the wrapper around the form, dont touch the DOM
  isInited: boolean; // dont start up the child DOM until this is true
}

const PaymentForm = React.lazy(() => import("./forms/PaymentForm"));

class Page extends Component<
  RouteComponentProps & PropsFromState,
  PaymentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      isInited: false,
    };
  }

  /*
    This payment form is handled outside of React so we need to be reaaaly
    careful not to do multiple fast state changes as it will break the DOM.
   */

  public componentDidMount(): void {
    this.check();
  }

  public componentDidUpdate(): void {
    this.check();
  }

  // check if we should show the form or bail
  public check() {
    if (this.state.isInited) {
      // we've already inited but something happened
      return;
    }
    const { profile } = this.props;
    if (profile) {
      if (profile.isLoading || !profile.SubscriptionData) {
        // wait for profile
        return;
      }
      if (!profile.SubscriptionData) {
        toast.warn("You don't have a valid subscription.");
        this.props.history.push(Path.PROFILE);
        return;
      }
      this.setState({ isInited: true });
      return;
    }
  }

  public render() {
    // waiting for data
    if (!this.state.isInited) {
      return (
        <div className="app-body" title="Loading data..">
          <LoadingIndicator />
          <span className="text-center">Loading...</span>
        </div>
      );
    }

    if (this.state.isLoading) {
      // this will unload braintree
      return (
        <div className="app-body" title="Loading data..">
          <LoadingIndicator />
          <span className="text-center">
            Please wait. We are processing your details, this can take a few
            minutes.
          </span>
        </div>
      );
    }

    const onCancel = () => {
      this.props.history.push(Path.PROFILE);
    };

    const labels: Labels = {
      submitLabel: "Update details",
    };

    if (
      this.props.promotion &&
      this.props.promotion.data &&
      this.props.promotion.data.labels &&
      this.props.promotion.data.labels
    ) {
      const promoLabels = this.props.promotion.data.labels;
      labels.submitLabel =
        promoLabels.pagePaymentUpdateSubmitLabel || "Update details";
      labels.zipLabel = promoLabels.pagePaymentZipLabel || "Zip";
    }

    const onToken = this.onToken.bind(this);
    const onApplePay = this.onApplePay.bind(this);
    return (
      <div className="app-body center payment-page">
        <div className="component-box with-header">
          <div className="component-box-header">
            <h1 className="font-regular">Update payment details</h1>
          </div>
          <React.StrictMode>
            <React.Suspense fallback={<LoadingIndicator />}>
              <PaymentForm
                onToken={onToken}
                onApplePay={onApplePay}
                labels={labels}
                onCancel={onCancel}
              />
            </React.Suspense>
          </React.StrictMode>
        </div>
        <div className="component-after" />
      </div>
    );
  }

  private onApplePay() {
    // TODO: implement
  }

  private async onToken(token: HostedFieldsTokenizePayload) {
    this.setState({ isLoading: true });

    const { history } = this.props;

    events.updatePayment.authorized();
    return await braintreeUpdatePayment({
      paymentMethodNonce: token.nonce,
    })
      .then((result) => {
        toast.success("Payment method updated");
        // payment success, redirect to profile, It'll reload the profile data
        history.push(Path.PROFILE);
        events.updatePayment.success();
        return Promise.resolve();
      })
      .catch((e) => {
        toast.error(e.message);
        events.updatePayment.error(e);
        this.setState({ isLoading: false });
      });
  }
}

interface PropsFromState {
  profile?: ProfileState;
  promotion?: PromotionState;
}

export const PaymentUpdatePage = connect<
  PropsFromState,
  any,
  any,
  ApplicationState
>(({ promotion, profile }: ApplicationState) => ({
  profile,
  promotion,
}))(withRouter(Page));

export default PaymentUpdatePage;
