import React from "react";
import { ReactComponent as PlusIcon } from "./images/plus.svg";
import { Component } from "react";
import { RefObject } from "react";

interface Props {
  title: string;
  children: any;
}

interface State {
  isOpen: boolean;
}

export class SlidingTab extends Component<Props, State> {
  private readonly innerDiv: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.innerDiv = React.createRef();
    this.state = {
      isOpen: false,
    };
  }

  public toggleOpen(): void {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  public render() {
    const title = this.props.title;
    const children = this.props.children;
    const toggleMenu = this.toggleOpen.bind(this);
    const isOpen = this.state.isOpen;

    const maxHeight = isOpen && this.innerDiv.current ? `1000px` : "0px";

    return (
      <div className={isOpen ? "sliding-tab open" : "sliding-tab"}>
        <h4 onClick={toggleMenu}>
          <span>{title}</span>
          <PlusIcon className="plus" />
        </h4>
        <div
          className="outer"
          style={{
            maxHeight,
          }}
        >
          <div className="inner" ref={this.innerDiv}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
