import React from "react";

import { TableColumn, SortColumn } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

const TableHeader = ({
  columns,
  sortColumn,
  onSort,
}: {
  columns: TableColumn[];
  sortColumn: SortColumn;
  onSort: (sortColumn: SortColumn) => void;
}) => {
  const raiseSort = (path: string) => {
    if (sortColumn.path === path) {
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    } else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    onSort(sortColumn);
  };

  const renderSortIcon = (column: TableColumn) => {
    if (column.path === sortColumn.path && sortColumn.order) {
      return (
        <FontAwesomeIcon
          icon={sortColumn.order === "asc" ? faSortUp : faSortDown}
        />
      );
    } else {
      return <FontAwesomeIcon icon={faSort} />;
    }
  };
  return (
    <thead>
      <tr className="table-header">
        {columns.map((column) => (
          <th
            className="header-label"
            key={column.path || column.key}
            onClick={() => raiseSort(column.path)}
          >
            {column.label}
            {renderSortIcon(column)}
          </th>
        ))}
      </tr>
      <tr className="spacer" />
    </thead>
  );
};

export default TableHeader;
