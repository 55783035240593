import React, { useState, useEffect } from "react";
import { DropDownOption } from "./types";
import DropDownItem from "./DropDownItem";

import "./DropDownMenu.scss";

import downIcon from "./images/down.svg";

interface Props {
  noSelectionTitle: string;
  options: DropDownOption[];
  onSelect: (selection: string, type?: string) => void;
  preSelectedOption?: string;
  allowClear?: boolean;
  selectionType?: string;
  disabled?: boolean;
}

const DropDownMenu = ({
  noSelectionTitle,
  options,
  onSelect,
  preSelectedOption,
  allowClear,
  selectionType,
  disabled = false,
}: Props) => {
  const [selection, setSelection] = useState<string | undefined>(
    preSelectedOption
  );
  const [selectionLabel, setSelectionLabel] = useState<string | undefined>();
  useEffect(() => {
    if (selection) {
      const selectedOption = options.find(
        (option) => (option.id || option.value) === selection
      );
      setSelectionLabel(selectedOption?.label);
    }
  }, [selection, options]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const clearSelection = () => {
    setSelection(preSelectedOption);
    setSelectionLabel(undefined);
    onSelect(preSelectedOption || "", selectionType);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onChange = (id: string) => {
    if (id === selection) {
      setSelection("");
      onSelect("", selectionType);
    } else {
      setSelection(id);
      onSelect(id, selectionType);
    }
  };

  let dropDownClass = "drop-down";
  if (isOpen) {
    dropDownClass += " open";
  } else if (selection) {
    dropDownClass += " with-selection";
  }

  return (
    <div
      className={dropDownClass}
      onClick={toggleMenu}
      id={selectionType + "-drop-down-toggle"}
      style={disabled ? { pointerEvents: "none" } : {}}
    >
      <div className="drop-down-title">
        <p className="title-text">{selectionLabel || noSelectionTitle}</p>
        <img className="drop-down-arrow" src={downIcon} alt="down arrow" />
      </div>
      {isOpen && (
        <React.Fragment>
          {allowClear && (
            <p className="clear-selection" onClick={clearSelection}>
              Clear selection
            </p>
          )}
          <div className="options">
            {options.map((option) => (
              <DropDownItem
                option={option}
                isSelected={option.id === selection}
                onClick={onChange}
                key={option.id || option.value}
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DropDownMenu;
