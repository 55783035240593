import qs from "query-string";
import api from "../api";

interface AdTrackingData {
  purchaseId: string;
  utm_parameters: CampaignParameters;
}
type CampaignParameters = { [key: string]: string };

const utmQueryStrings = [
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_content",
  "keyword",
];

export function saveTagManagerParametersToLocalStorage(search: string): void {
  const queryStrings = qs.parse(search);
  for (const [key, value] of Object.entries(queryStrings)) {
    if (
      (typeof value === "string" && key.includes("utm")) ||
      value === "keyword"
    ) {
      localStorage.setItem(key, value);
    }
  }
}

export function getTagManagerParametersFromLocalStorage(): CampaignParameters {
  const campaignParameters: CampaignParameters = {};
  for (const value of utmQueryStrings) {
    const item = localStorage.getItem(value);
    if (item) {
      campaignParameters[value] = item;
    }
  }
  return campaignParameters;
}

export function removeTagManagerParametersFromLocalStorage(): CampaignParameters {
  const campaignParameters: CampaignParameters = {};
  for (const value of utmQueryStrings) {
    localStorage.removeItem(value);
  }
  return campaignParameters;
}

export async function sendAdTrackingData(
  adTrackingData: AdTrackingData
): Promise<void> {
  try {
    await api.post("/tracking/trial/web", adTrackingData);
  } catch (e) {
    // fail invisibly
  }
}
