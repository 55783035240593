export enum Path {
  LANDING = "/subscribe",

  REDEEM_CODE_LANDING_LEGACY = "/redeem-code",
  REDEEM_CODE_LANDING = "/redeem",
  REDEEM_CODE_INPUT = "/redeem/input",
  REDEEM_CODE_CONFIRMATION = "/redeem/confirmation",

  PLUSH_CODE_LANDING = "/sleepies",
  PLUSH_CODE_INPUT = "/sleepies/input",
  PLUSH_CODE_PAYMENT = "/sleepies/payment",
  PLUSH_CODE_CONFIRMATION = "/sleepies/confirmation",

  REFERRAL_CODE_LANDING = "/referral",

  PARTNER_CODE_LANDING = "/partners",
  PARTNER_CODE_INPUT = "/partners/input",
  PARTNER_CODE_PAYMENT = "/partners/payment",
  PARTNER_CODE_CONFIRMATION = "/partners/confirmation",

  SCHOOL_LANDING = "/schools",
  SCHOOL_SIGNUP = "/schools/signup",
  SCHOOL_CONFIRMATION = "/schools/confirmation",

  PAYMENT = "/payment",
  PAYMENT_CONFIRMATION = "/payment/confirmation",
  PAYMENT_UPDATE = "/payment/update",

  PROFILE = "/profile",
  SIGNUP = "/sign-up",

  LOGIN = "/login",
  RESET_PASSWORD = "/login/reset-password-form",
  RESET_PASSWORD_SET_NEW = "/login/reset-password",

  ERROR = "/error",
  CONTACT = "/contactus",

  SONG_LIST_SCHOOLS = "/schools/menu",
  SONG_SCHOOLS = "/schools/play",
}

// non standard promotions that we do not want to reload after navigating away from website.
export const SpecialPromotionsByPath: Record<string, string> = {
  [Path.REDEEM_CODE_LANDING_LEGACY]: "redeem",
  [Path.REDEEM_CODE_LANDING]: "redeem",
  [Path.REFERRAL_CODE_LANDING]: "referral",
  [Path.PLUSH_CODE_LANDING]: "sleepies",
  [Path.SCHOOL_LANDING]: "schools",

  // with trailing forward slash
  [Path.REDEEM_CODE_LANDING_LEGACY + "/"]: "redeem",
  [Path.REDEEM_CODE_LANDING + "/"]: "redeem",
  [Path.REFERRAL_CODE_LANDING + "/"]: "referral",
  [Path.PLUSH_CODE_LANDING + "/"]: "sleepies",
  [Path.SCHOOL_LANDING + "/"]: "schools",
};
