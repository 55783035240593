import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { ProfileState } from "../../../store/profile";
import { ApplicationState } from "../../../store";
import { RedeemType } from "shared/store/campaigns/types";
import { CodeRedeemError } from "shared/helpers/errors";

import { Path } from "../../../store/path/constants";
import { promoCodeRemember } from "../../../store/promotion/local";
import { profileSetAction } from "../../../store/profile";
import { promotionSetAction } from "../../../store/promotion";
import { redeemPromoCode } from "../../../store/promotion/api";

import RedeemCodeForm from "./forms/RedeemCodeForm";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import FlowIndicator from "../../flow/FlowIndicator";

import "./RedeemCode.scss";

const RedeemCode = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state: ApplicationState) => state.auth);
  const promotion = useSelector((state: ApplicationState) => state.promotion);

  const onRedeemMemorized = useCallback(() => {
    const promoCode = promotion.partner!.promoCode;
    if (promotion.partner && promotion.partner.isPromoCodeValid) {
      if (promoCode === promotion.partner.promoCode) {
        history.push(Path.PARTNER_CODE_PAYMENT);
        return;
      }
    }
  }, [promotion, history]);

  useEffect(() => {
    const { partner } = promotion;
    if (partner && !partner.showRedeemScreen) {
      if (promotion.promoCode) {
        onRedeemMemorized();
      } else if (partner.promoCode) {
        onRedeemMemorized();
      }
    }
  }, [promotion, onRedeemMemorized]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (promotion.data) {
      setIsLoading(false);
    }
  }, [promotion.data]);

  const onRedeem = async (promoCode: string) => {
    try {
      let response = await redeemPromoCode(auth, promoCode, location.pathname);
      if (response) {
        switch (response.redeemType) {
          case RedeemType.REFERRAL_CODE: {
            if (response.referralCodeData) {
              history.push(Path.REFERRAL_CODE_LANDING + `/${promoCode}`);
            }
            break;
          }
          case RedeemType.PRODUCT_CODE: {
            if (response.productCodeData!.productRedeemType === "free-access") {
              response = await redeemPromoCode(
                auth,
                promoCode,
                location.pathname,
                true
              );
              await applyFreeAccessSubscription(response);
            } else if (
              promotion.data!.promotionSlug ===
              response.productCodeData!.productRedeemPromotionSlug
            ) {
              dispatch(promotionSetAction({ promoCode }));
              promoCodeRemember(promoCode);
              history.push(Path.PLUSH_CODE_PAYMENT);
            } else {
              history.replace(Path.PLUSH_CODE_LANDING);
            }
            break;
          }
          case RedeemType.FREE_ACCESS_CODE: {
            await applyFreeAccessSubscription(response);
            break;
          }

          default:
            break;
        }
      }
    } catch (e) {
      if (e instanceof CodeRedeemError && e.urlRedirect) {
        if (e.urlRedirect.href.includes("app")) {
          history.replace(e.urlRedirect.pathname);
        } else {
          window.location.href = e.urlRedirect.href;
        }
      }
      toast.error(e.message);
    }
  };

  const applyFreeAccessSubscription = async (response: any) => {
    const state = {
      SubscriptionData: response.promoCodeSubscriptionData,
    } as ProfileState;
    dispatch(profileSetAction(state));
    history.push(Path.REDEEM_CODE_CONFIRMATION);
  };

  if (isLoading) {
    return (
      <div className="app-body" title="Loading data..">
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div className="app-body narrow center promo-redeem-page ">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">Enter your code</h1>
          <FlowIndicator stepName={"Redeem"} step={2} />
        </div>
        <RedeemCodeForm onRedeem={onRedeem} />
      </div>
    </div>
  );
};

export default RedeemCode;
