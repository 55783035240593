import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ApplicationState } from "../../store";
import * as profileActions from "../../store/profile/actions";

import withConfirmAnimation from "../higherOrderComponents/layout/withConfirmAnimation";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import FlowIndicator from "../flow/FlowIndicator";

import "../payment/Payment.scss";

interface Props {
  email: string;
  backgroundColor: string;
  componentBackgroundColor: string;
  emailBackgroundColor: string;
  loadingComplete: () => void;
}

const SchoolSignupConfirmation = ({
  backgroundColor,
  componentBackgroundColor,
  emailBackgroundColor,
  loadingComplete,
}: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(profileActions.profileFetchAction());
  }, [dispatch]);

  const profile = useSelector((state: ApplicationState) => state.profile);

  const [email, setEmail] = useState("");
  useEffect(() => {
    if (profile.UserData && profile.UserData.email) {
      setEmail(profile.UserData.email);
    }
  }, [profile.UserData]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (email) {
      setIsLoading(false);
      setTimeout(loadingComplete, 300);
    }
  }, [email, loadingComplete]);

  if (isLoading) {
    return (
      <div
        className="app-body  center payment-page payment-confirmation-page"
        title="Loading data.."
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div
      className="app-body center payment-page payment-confirmation-page"
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className="component-box with-header"
        style={{ backgroundColor: componentBackgroundColor }}
      >
        <div className="component-box-header">
          <FlowIndicator stepName={"Confirmation"} step={2} stepCount={2} />
          <h1 className="font-heading">Welcome to Moshi for Schools!</h1>
        </div>
        <h1 className="text-center top-title">
          Thanks for joining us. You now have access to over 400 audio tracks
          that you can stream directly into your classroom.
        </h1>
        <NavLink className="btn filled action " to="/schools/menu">
          Start Playing
        </NavLink>
        <p className="text-center m-1 mt-2">
          Moshi is free for educators so we verify all users. If we need
          additional information, we will reach out to you at {email}. If you
          require any support, reach out to schools@moshikids.com.
        </p>
      </div>
      <div className="component-after" />
    </div>
  );
};

export default withConfirmAnimation(SchoolSignupConfirmation);
