import React from "react";
import { LessonPlanData } from "../../../store/content";
import LoadingIndicator from "../../../../shared/components/loading/LoadingIndicator";

import "./LessonPlans.scss";

const LessonDetail = React.lazy(() => import("./LessonDetail"));

const LessonPlans = ({ lessons }: { lessons: LessonPlanData[] }) => {
  return (
    <div className="lesson-plans" id="lesson-plans">
      <h1>Resources for the classroom</h1>
      <div className="plans-container">
        <React.StrictMode>
          <React.Suspense fallback={<LoadingIndicator />}>
            {lessons.map((lesson) => (
              <LessonDetail
                key={lesson.lessonPlanId}
                lessonPlanId={lesson.lessonPlanId}
                title={lesson.title}
                type={lesson.type}
                ageGroup={lesson.ageGroup}
                mediaUrl={lesson.mediaUrl}
              />
            ))}
          </React.Suspense>
        </React.StrictMode>
      </div>
    </div>
  );
};

export default LessonPlans;
