import React, { useState, useEffect } from "react";

import "./PlayButton.scss";

import playIcon from "../icons/Play@1.5x.svg";
import pauseIcon from "../icons/Pause@1.5x.svg";

const PlayButton = ({
  playing,
  play,
}: {
  playing: boolean;
  play: () => void;
}) => {
  const [icon, setIcon] = useState(playIcon);
  useEffect(() => {
    if (playing) {
      setIcon(pauseIcon);
    } else {
      setIcon(playIcon);
    }
  }, [playing]);

  const onClick = () => {
    play();
  };

  return (
    <img
      className="play-button"
      src={icon}
      id="play"
      alt="play"
      onClick={onClick}
    />
  );
};

export default PlayButton;
