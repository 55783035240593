export interface Link {
  label: string;
  href: string;
}

export const searchLink: Link = {
  label: "Search",
  href: process.env.REACT_APP_WEB_URL + "/?s=",
};

export const companyLinks: Link[] = [
  { label: "Contact", href: process.env.REACT_APP_WEB_URL + "/contact" },
  { label: "Careers", href: process.env.REACT_APP_WEB_URL + "/careers" },
  { label: "Press", href: process.env.REACT_APP_WEB_URL + "/press" },
];

export const communityLinks: Link[] = [
  { label: "Articles", href: process.env.REACT_APP_WEB_URL + "/articles" },
  {
    label: "Schools",
    href: process.env.REACT_APP_WEB_URL + "/moshi-for-schools",
  },
  {
    label: "Lessons",
    href: process.env.REACT_APP_WEB_URL + "/schools/lesson-plans",
  },
];

export const appLinks: Link[] = [
  { label: "FAQs", href: process.env.REACT_APP_WEB_URL + "/faqs" },
  { label: "Account", href: "/profile" },
];

export const socialLinks: Link[] = [
  { label: "Instagram", href: "https://www.instagram.com/moshisleep/" },
  {
    label: "YouTube",
    href: "https://www.youtube.com/channel/UCkNtx91j9IKnMTpQqH6BQKA",
  },
  { label: "Facebook", href: "https://www.facebook.com/MoshiSleep/" },
  { label: "Whatsapp", href: "https://wa.me/447956015120" },
];
