import React, { useState, useEffect, useCallback, useRef } from "react";
import lottie, { AnimationItem } from "lottie-web";
import { Howl } from "howler";
import { StoryData } from "../../../../store/content";
import { BreathingState } from "./types";
import LoadingIndicator from "../../../../../shared/components/loading/LoadingIndicator";
import BreathingText from "./BreathingText";
import PlayButton from "./PlayButton";

import "../StoryPlayer.scss";

const BreathingPlayer = ({
  baseUrl,
  storyData,
  onPlay,
  onEnded,
}: {
  baseUrl: string;
  storyData: StoryData;
  onPlay: () => void;
  onEnded: () => void;
}) => {
  useEffect(() => {
    lottie.setQuality(2);
  }, []);

  useEffect(
    () => () => {
      lottie.destroy();
    },
    []
  );

  const [isPlaying, setIsPlaying] = useState(false);
  const [breathingAudio] = useState(
    new Howl({
      src: baseUrl + storyData.audioUrl,
      autoplay: false,
    })
  );
  useEffect(() => {
    return () => {
      breathingAudio.unload();
    };
  }, [breathingAudio]);
  const [breathingAnimation, setBreathingAnimation] = useState<AnimationItem>();
  const [breathingTextState, setBreathingTextState] =
    useState<BreathingState>("WELCOME");
  const breatheInTimes = [1, 8, 17, 24, 33, 40, 49, 57];
  const breatheOutTimes = [5, 12, 21, 28, 37, 44, 54, 61];
  const endTime = 71;
  const breathCount = useRef(0);
  const handleBreathingTextChange = useCallback(
    (frameTime: number) => {
      if (frameTime === 0) {
        return;
      }
      if (
        frameTime > breatheInTimes[breathCount.current] &&
        frameTime < breatheOutTimes[breathCount.current]
      ) {
        setBreathingTextState("BREATHE_IN");
      } else if (
        (frameTime < breatheInTimes[breathCount.current + 1] ||
          breathCount.current + 1 >= breatheInTimes.length) &&
        frameTime > breatheOutTimes[breathCount.current]
      ) {
        setBreathingTextState("BREATHE_OUT");
        breathCount.current += 1;
      } else if (frameTime > endTime) {
        setBreathingTextState("END");
      }
    },
    [breatheInTimes, breatheOutTimes]
  );
  useEffect(() => {
    const container = document.querySelector("#lottie-breathing");
    if (container && !breathingAnimation) {
      const lottieAnimation = lottie.loadAnimation({
        container: container,
        name: "breathe",
        path: baseUrl + storyData.animationUrl,
        autoplay: false,
        loop: false,
        renderer: "svg",
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      });
      lottieAnimation.addEventListener("enterFrame", () => {
        handleBreathingTextChange(
          Math.ceil(lottieAnimation.currentFrame / lottieAnimation.frameRate)
        );
      });
      lottieAnimation.addEventListener("complete", () => {
        onEnded();
        setIsPlaying(false);
        breathingAudio.stop();
        lottieAnimation.goToAndStop(0, true);
        breathCount.current = 0;
      });
      setBreathingAnimation(lottieAnimation);
    }
  }, [
    baseUrl,
    storyData.animationUrl,
    breathingAnimation,
    breathingAudio,
    handleBreathingTextChange,
    onEnded,
    isPlaying,
  ]);

  const [introComplete, setIntroComplete] = useState(false);
  const [introAudio] = useState(
    new Howl({
      src: baseUrl + storyData.introAudioUrl,
      autoplay: false,
    })
  );
  useEffect(() => {
    return () => {
      introAudio.unload();
    };
  }, [introAudio]);
  const [introAnimation, setIntroAnimation] = useState<AnimationItem>();
  useEffect(() => {
    const introContainer = document.querySelector("#lottie-intro");
    if (introContainer && !introAnimation) {
      const lottieAnimation = lottie.loadAnimation({
        container: introContainer,
        name: "intro",
        path: baseUrl + storyData.introAnimationUrl,
        autoplay: false,
        loop: false,
        renderer: "svg",
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      });
      lottieAnimation.addEventListener("complete", () => {
        setIntroComplete(true);
        breathingAudio.play();
        lottie.play("breathe");
      });
      setIntroAnimation(lottieAnimation);
    }
  }, [baseUrl, storyData.introAnimationUrl, introAnimation, breathingAudio]);

  const play = () => {
    const audioPlayer = introComplete ? breathingAudio : introAudio;
    const animationName = introComplete ? "breathe" : "intro";
    if (!audioPlayer.playing()) {
      onPlay();
      audioPlayer.play();
      lottie.play(animationName);
      setIsPlaying(true);
    } else {
      audioPlayer.pause();
      lottie.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="player">
      {!introAnimation && <LoadingIndicator />}
      <BreathingText
        characterName={storyData.characterName}
        state={breathingTextState}
      />
      <div
        id="lottie-intro"
        className={"lottie" + (introComplete ? " hidden" : "")}
      />
      <div
        id="lottie-breathing"
        className={"lottie" + (!introComplete ? " hidden" : "")}
      />
      <PlayButton playing={isPlaying} play={play} />
    </div>
  );
};

export default BreathingPlayer;
