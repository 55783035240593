import {
  ClientCognitoDevice,
  ClientDynamoUser,
  ClientSubscriptionData,
} from "shared/store/user/types";

export interface ProfileState {
  isLoading?: boolean;
  userSub?: string | undefined;

  UserData?: ClientDynamoUser | undefined;
  SubscriptionData?: ClientSubscriptionData | undefined;
  DeviceList?: ClientCognitoDevice[] | undefined;
}

export enum ProfileActionsTypes {
  PROFILE_SET = "@@profile/PROFILE_SET",
  PROFILE_FETCH = "@@profile/PROFILE_FETCH",
  PROFILE_UPDATE = "@@profile/PROFILE_UPDATE",
  PROFILE_DEVICES_FETCH = "@@profile/PROFILE_DEVICES_FETCH",
  PROFILE_DEVICE_DELETE = "@@profile/PROFILE_DEVICE_DELETE",
}
