import React, { useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import qs, { ParsedQuery } from "query-string";

import { ApplicationState } from "./store";
import {
  promotionSetAction,
  PartnerInfo,
  ReferralInfo,
  PromotionState,
} from "./store/promotion";
import {
  partnerForget,
  promoCodeForget,
  promoCodeRecall,
  promoCodeRemember,
  promotionRecall,
  promotionRemember,
} from "./store/promotion/local";
import { getPromotion, PromotionNotFound } from "./store/promotion/api";
import { SpecialPromotionsByPath } from "./store/path/constants";
import { Default } from "./helpers/constants";
import { captureException } from "./helpers/logger";
import { saveTagManagerParametersToLocalStorage } from "./helpers/analytics/tagManager";
import {
  getCountryOverrideFromQueryString,
  getPartnerInfoFromPath,
  getPromotionSlugFromPath,
  getPromotionSlugFromQueryString,
  getReferralDetailsFromPath,
  getReferralDetailsFromQueryString,
  getSpecialPromotionSlugFromPath,
  shouldGetPartnerFromPath,
  shouldGetPromotionFromPath,
  shouldGetReferralFromPath,
  shouldGetSpecialPromoFromPath,
} from "./helpers/promotion/PromotionHelper";

import { toast, ToastContainer } from "react-toastify";
import WhatsApp from "./components/contact/WhatsApp";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Router from "./Router";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Notifications from "./components/notifications/Notifications";

const App = () => {
  const dataLayer = window.dataLayer;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const promotionSet = useCallback(
    (newPromotion: PromotionState) =>
      dispatch(promotionSetAction(newPromotion)),
    [dispatch]
  );

  const redirectToDefault = useCallback(() => {
    history.replace("/subscribe");
    partnerForget();
    promotionRemember(Default.SUBSCRIPTION_PLAN);
    return Default.SUBSCRIPTION_PLAN;
  }, [history]);

  const canBailEarly = useCallback(
    (
      promotionSlug: string | undefined,
      countryOverride: string | undefined
    ): boolean => {
      let bail = false;
      if (
        !promotionSlug ||
        (promotion.data && promotion.data.promotionSlug === promotionSlug)
      ) {
        bail = true;
      }
      if (
        bail &&
        countryOverride &&
        (!promotion.data || promotion.data.countryCode !== countryOverride)
      ) {
        bail = false;
      }
      return bail;
    },
    [promotion.data]
  );

  const onLandingPage = useCallback(() => {
    return (
      location.pathname.includes("/subscribe") || location.pathname === "/"
    );
  }, [location.pathname]);

  const loadPromotion = useCallback(
    async (search: string, init: boolean) => {
      let countryOverride: string | undefined = promotion.country;
      let promotionSlug: string | undefined;
      let partnerPromoData: PartnerInfo | undefined;
      let referralDetails: ReferralInfo | undefined;

      if (search) {
        const query: ParsedQuery = qs.parse(search);
        if (query) {
          if (query.promo) {
            promotionSlug = getPromotionSlugFromQueryString(query);
          }
          if (query.country) {
            countryOverride = getCountryOverrideFromQueryString(query);
            await promotionSet({ country: countryOverride });
          }
          if (query.code) {
            // referral code from deeplink
            promotionSlug = "referral";
            referralDetails = await getReferralDetailsFromQueryString(query);
          }
        }
      }

      if (location.pathname) {
        const path = location.pathname
          .split("/")
          .filter((s: string) => s !== "");
        if (!promotionSlug && shouldGetPromotionFromPath(path)) {
          promotionSlug = getPromotionSlugFromPath(path);
        } else if (shouldGetPartnerFromPath(location.pathname, path)) {
          partnerPromoData = await getPartnerInfoFromPath(path);
          if (partnerPromoData) {
            promotionSlug = partnerPromoData.promotionSlug;
            promoCodeRemember(partnerPromoData.promoCode);
          } else {
            promotionSlug = redirectToDefault();
          }
        } else if (shouldGetReferralFromPath(path) && !referralDetails) {
          referralDetails = await getReferralDetailsFromPath(path);
          if (referralDetails) {
            promotionSlug = referralDetails.subscriptionSku;
          } else {
            promotionSlug = redirectToDefault();
          }
        } else if (shouldGetSpecialPromoFromPath(path)) {
          promotionSlug = getSpecialPromotionSlugFromPath(path);
        }
      }

      if (!init && canBailEarly(promotionSlug, countryOverride)) {
        return;
      }

      if (!promotionSlug) {
        if (!document.referrer.includes("moshikids.com")) {
          promotionSlug = promotionRecall();
        }
        if (
          onLandingPage() &&
          !location.pathname.includes(promotionSlug as string) &&
          Object.values(SpecialPromotionsByPath).includes(
            promotionSlug as string
          )
        ) {
          promotionSlug = Default.SUBSCRIPTION_PLAN;
        }
      }

      try {
        // the promotion has changed on reload - clear promotion specific store
        if (promotionSlug !== promotionRecall()) {
          promoCodeForget();
          partnerForget();
        }
        const promoCode = partnerPromoData
          ? partnerPromoData.promoCode
          : referralDetails
          ? referralDetails.referralCode
          : promoCodeRecall() || undefined;

        const newPromotion = await getPromotion(promotionSlug, countryOverride);
        dispatch(
          promotionSetAction({
            error: undefined,
            data: newPromotion,
            partner: partnerPromoData,
            referral: referralDetails,
            promoCode,
          })
        );
        // set promotion slug in dataLayer for optimize
        dataLayer.push({ promo: newPromotion.promotionSlug });
        promotionRemember(newPromotion.promotionSlug);
      } catch (e) {
        if (
          e instanceof PromotionNotFound &&
          promotionSlug &&
          promotionSlug !== Default.SUBSCRIPTION_PLAN
        ) {
          toast.error("Could not find promotion");
          redirectToDefault();
        } else {
          // Failed to load default promotion. Something is wrong.
          dispatch(promotionSetAction({ data: undefined, error: e }));
          toast.error("Error loading data");
          captureException(e, {
            category: "braintree.promotion",
          });
          return;
        }
      }
    },
    [
      canBailEarly,
      dataLayer,
      dispatch,
      location.pathname,
      onLandingPage,
      promotion.country,
      promotionSet,
      redirectToDefault,
    ]
  );

  const init = useRef(true);
  useEffect(() => {
    loadPromotion(location.search, init.current).then(
      () => (init.current = false)
    );
    saveTagManagerParametersToLocalStorage(location.search);
  }, [loadPromotion, location.search]);

  return (
    <div className="app-container">
      <ToastContainer autoClose={15000} />
      <Notifications />
      <WhatsApp />
      <Header />
      <Router />
      <Footer />
    </div>
  );
};

export default App;
