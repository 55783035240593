import React from "react";
import "./Trustbox.scss";

declare global {
  interface Window {
    Trustpilot: any;
  }
}

const TrustBox = () => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className="trustpilot">
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="60c7362830265100012a8395"
        data-style-height="80px"
        data-style-width="100%"
      >
        <a
          href="https://uk.trustpilot.com/review/moshikids.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};
export default TrustBox;
