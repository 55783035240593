import React from "react";
import { RouteComponentProps } from "react-router";
import { Path } from "../../../store/path/constants";

import { toast } from "react-toastify";
import { forgotPassword } from "shared/store/auth/api";

import RequestResetPasswordForm, {
  IRequestResetPassword,
} from "./forms/RequestResetPasswordFrom";

import "../login/Login.scss";
const RequestPasswordReset = ({ history }: RouteComponentProps) => {
  const sendPasswordResetRequest = async (
    passwordResetData: IRequestResetPassword
  ) => {
    try {
      const response = await forgotPassword(passwordResetData.email);
      if (
        response.CodeDeliveryDetails &&
        response.CodeDeliveryDetails.Destination
      ) {
        toast.success(
          `A password reset link was sent to ${response.CodeDeliveryDetails.Destination}.`
        );
      } else {
        toast.success(`A password reset link was sent to your email.`);
      }
      history.push(Path.LOGIN);
    } catch (e) {
      toast.error("Failed to send password reset link.");
    }
  };

  return (
    <div className="app-body box center login-page">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">Forgotten password?</h1>
          <p className="subtitle">
            We will email you a link to reset your password
          </p>
        </div>
        <RequestResetPasswordForm sendRequest={sendPasswordResetRequest} />
      </div>
    </div>
  );
};

export default RequestPasswordReset;
