import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import {
  ConfirmationDownloads,
  getConfirmationDownloadsByPromotion,
} from "../../store/downloads/constants";
import DownloadButton from "./DownloadButton";
import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";
import "./Download.scss";

import appleStoreButton from "../payment/images/apple.svg";
import googleStoreButton from "../payment/images/google.svg";
import amazonStoreButton from "../payment/images/amazon.svg";

const Download = () => {
  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const [downloadLinks, setDownloadLinks] = useState<ConfirmationDownloads>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (promotion.data) {
      setDownloadLinks(getConfirmationDownloadsByPromotion(promotion));
    }
  }, [promotion, promotion.data]);

  useEffect(() => {
    if (downloadLinks) {
      setIsLoading(false);
    }
  }, [downloadLinks]);

  if (isLoading) {
    return (
      <React.Fragment>
        <LoadingIndicator />
      </React.Fragment>
    );
  } else {
    return (
      <div className="download-widget">
        <div>
          <DownloadButton
            link={downloadLinks!.iosLink}
            id="ga-download-apple"
            image={appleStoreButton}
            imageAltText="Apple"
          />
        </div>
        <div>
          <DownloadButton
            link={downloadLinks!.googleLink}
            id="ga-download-google-play"
            image={googleStoreButton}
            imageAltText="Google Play"
          />
        </div>
        <div>
          <DownloadButton
            className="amazon"
            link={downloadLinks!.amazonLink}
            id="ga-download-amazon"
            image={amazonStoreButton}
            imageAltText="Amazon"
          />
        </div>
      </div>
    );
  }
};

export default Download;
