import React from "react";
import {
  companyLinks,
  communityLinks,
  appLinks,
  socialLinks,
} from "./data/links";
import { getStandardLinks } from "../../store/downloads/constants";
import { mobileFooterMenuItems } from "../header/constants";
import LinkGroup from "./sections/LinkGroup";
import HeaderLogo from "../header/shared/HeaderLogo";
import DownloadButton from "../download/DownloadButton";

import "./Footer.scss";

import appleStoreButton from "../payment/images/apple.svg";
import googleStoreButton from "../payment/images/google.svg";

const Footer = () => {
  const downloadLinks = getStandardLinks();
  return (
    <div className="footer">
      <section className="footer-content-wrapper">
        <div className="footer-website-links">
          <LinkGroup header="Company" links={companyLinks} />
          <LinkGroup header="Content" links={communityLinks} />
          <LinkGroup header="App" links={appLinks} />
          <LinkGroup header="Social" links={socialLinks} />
        </div>
        <div className="footer-app-links">
          <HeaderLogo menuOpen={true} />
          <div className="download-button-wrapper">
            <DownloadButton
              className="padding-right"
              link={downloadLinks.iosLink}
              id={"ga-download-apple"}
              image={appleStoreButton}
              imageAltText="Apple"
            />
            <DownloadButton
              className="padding-right"
              link={downloadLinks.googleLink}
              id={"ga-download-apple"}
              image={googleStoreButton}
              imageAltText="Google Play"
            />
          </div>

          <ul className="footer-links-legal">
            {mobileFooterMenuItems.map((item) => (
              <li key={item.label + "li"}>
                <a
                  key={item.label}
                  className="footer-links-row-item"
                  href={item.href}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-terms">
          <p>
            © Mind Candy Ltd. Moshi is a trademark of Mind Candy Ltd. All rights
            reserved. Mind Candy Ltd is a company registered in England; Company
            number: #05119483; Registered office: 4th Floor Bonhill Building, 15
            Bonhill Street, London EC2A 4DN, UK
          </p>
        </div>
      </section>
    </div>
  );
};

export default Footer;
