import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper";
import { Path } from "../../../../store/path/constants";
import { getReviewColor, reviews } from "./constants";
import { Labels } from "../../../../store/promotion";
import { NavLink } from "react-router-dom";
import ReviewCard from "./ReviewCard";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./Reviews.scss";

import nextIcon from "../../images/next.svg";
import prevIcon from "../../images/prev.svg";

SwiperCore.use([Mousewheel, Keyboard]);

interface Props {
  labels: Labels | undefined;
  showFreeTrial: boolean;
  onClick: (s: string) => void;
}
const Reviews = ({ labels, showFreeTrial, onClick }: Props) => {
  const navigationNextElement = useRef(null);
  const navigationPrevElement = useRef(null);

  const ctaClick = () => {
    onClick("reviews");
  };

  return (
    <div className="reviews">
      <h1 className="font-heading center">Trusted by parents</h1>
      <Swiper
        className="reviews-swiper swiper-container"
        breakpoints={{
          "0": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "640": {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          "768": {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          "1024": {
            slidesPerView: 3,
            spaceBetween: 75,
          },
          "1800": {
            slidesPerView: 4,
            spaceBetween: 75,
          },
        }}
        grabCursor={true}
        centeredSlides={true}
        modules={[Navigation, Keyboard]}
        navigation={{
          nextEl: navigationNextElement.current,
          prevEl: navigationPrevElement.current,
        }}
        mousewheel={{ forceToAxis: true }}
        keyboard
        loop
      >
        {reviews.map((review, i) => (
          <SwiperSlide key={i}>
            <ReviewCard
              key={i}
              review={review}
              backgroundColor={getReviewColor(i)}
            />
          </SwiperSlide>
        ))}
        <img
          ref={navigationNextElement}
          className="swiper-button-next"
          src={nextIcon}
          alt="Next"
        />
        <img
          ref={navigationPrevElement}
          className="swiper-button-prev"
          src={prevIcon}
          alt="Previous"
        />
      </Swiper>
      <NavLink
        to={Path.PAYMENT}
        className="btn action filled"
        onClick={ctaClick}
      >
        {(showFreeTrial
          ? labels?.pageSubscribeMainCtaButton
          : labels?.pageSubscribeMainCtaButtonNoTrial) || "Sign up"}
      </NavLink>
    </div>
  );
};

export default Reviews;
