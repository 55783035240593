import React from "react";
import "./ErrorPage.scss";
import { RouteComponentProps } from "react-router";
import qs from "query-string";

export class ErrorPage extends React.PureComponent<RouteComponentProps> {
  public render() {
    let message = <>Something went wrong.</>;

    if (this.props.location.search) {
      const query: any = qs.parse(this.props.location.search);
      if (query.type) {
        switch (query.type) {
          case "404":
            message = (
              <>
                Our Moshlings could not find that page. <br /> Please try again
                while they rest up.
              </>
            );
        }
      }
      if (query.message) {
        message = query.message;
      }
    }

    return (
      <div className="app-body error-page">
        <div className="component-box-wrap text-center">
          <h1 className="font-bold">Oh Dear</h1>
          <h2 className="font-light">{message}</h2>
          <img className="sleeping-moshi" src="/media/yawnsey.png" alt="" />
        </div>
      </div>
    );
  }
}
