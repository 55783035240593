import React from "react";
import Modal from "react-modal";
import { ModalAlertProps } from "./modal.types";
// @ts-ignore
import LinkButton from "../LinkButton";

import "./ModalAlert.scss";

const ModalAlert = (props: ModalAlertProps) => {
  const {
    isOpen,
    title,
    description,
    actionText,
    close
  }: {
    isOpen: boolean;
    title: string;
    description: string;
    actionText: string;
    close: () => any;
  } = props;
  const webPrefix =
    process.env.REACT_APP_WEB_URL || "https://www.moshikids.com";

  return (
    <Modal isOpen={isOpen} onRequestClose={close} className="Modal-small">
      <h2 className="title">{title}</h2>
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="actions buttons">
        <button className="btn secondary filled" onClick={close}>
          {actionText}
        </button>
      </div>
      <div className="mt-1 mb-2">
        <LinkButton text="Contact Us" link={webPrefix + "/contactus"} />
      </div>
    </Modal>
  );
};

export default ModalAlert;
