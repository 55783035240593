import React from "react";
import { SITE_KEY } from "../../store";
import ReCAPTCHA from "react-google-recaptcha";

interface ComponentProps {
  onChangeComplete: (token: string) => any;
  ref: React.RefObject<ReCaptcha>;
}

interface ComponentState {
  hasReset: boolean;
  isValid: boolean;
}

export class ReCaptcha extends React.Component<ComponentProps, ComponentState> {
  private component: React.RefObject<ReCAPTCHA> = React.createRef();

  constructor(props: ComponentProps) {
    super(props);
    this.execute.bind(this);
    this.isValid.bind(this);
    this.hasReset.bind(this);
    this.state = {
      hasReset: true,
      isValid: false,
    };
  }

  public execute(): void {
    if (this.component.current) {
      this.component.current.execute();
    }
  }

  public isValid(): boolean {
    return this.state.isValid && !this.state.hasReset;
  }

  public reset() {
    if (this.component.current) {
      this.component.current.reset();
      this.component = React.createRef();
    }
    this.setStateImmediately({ hasReset: true, isValid: false });
  }

  public hasReset(): boolean {
    return this.state.hasReset;
  }

  public render() {
    const recaptcha = this.component;
    const onChange = this.onChange.bind(this);
    return (
      <div style={{ margin: "auto" }}>
        <ReCAPTCHA ref={recaptcha} sitekey={SITE_KEY} onChange={onChange} />
      </div>
    );
  }

  private async onChange(token: string | null): Promise<void> {
    if (this.component.current) {
      if (token) {
        this.props.onChangeComplete(token);
      } else {
        this.reset();
      }
    }
  }

  private setStateImmediately(state: ComponentState) {
    this.setState(state);
  }
}
