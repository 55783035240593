import logger from "./clients/helpers/logger";
import * as serviceWorker from "./serviceWorker";
import React from "react";

import configureStore from "shared/configureStore";
import { ApplicationState, rootReducer, rootSaga } from "clients/store";

import { initClientUser } from "clients/store/profile/helpers";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "clients/App";
import { promotionSetAction, PromotionState } from "./clients/store/promotion";
import { initHook } from "./clients/helpers/hooks";
import { profileSetAction, ProfileState } from "./clients/store/profile";

export const store = configureStore<ApplicationState>(
  {} as ApplicationState,
  rootReducer,
  rootSaga
);

/**
 * Setup Hooks
 * - make sure the state is updated if google optimize is late
 */
initHook(
  "promotion-set",
  async (filter: (promotionState: PromotionState) => PromotionState) => {
    const { promotion } = store.getState();
    store.dispatch(promotionSetAction(promotion));
  }
);
initHook(
  "profile-set",
  async (filter: (promotionState: ProfileState) => ProfileState) => {
    const { profile } = store.getState();
    store.dispatch(profileSetAction(profile));
  }
);

/**
 * These can be run in sync, the frontend will wait if it loads before them
 */
initClientUser(store).then();

/**
 * Start up sentry/analytics
 */
logger.init();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root") as HTMLElement
);

// 2019-05-11 Marko Zabreznik
// We're not using service workers since the app is interlaced with another CMS
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
