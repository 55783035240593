import { ContentActionTypes, ContentState } from "./types";

export interface ContentSet {
  type: ContentActionTypes.CONTENT_SET;
  payload: Partial<ContentState>;
}

export interface ContentFetch {
  type: ContentActionTypes.CONTENT_FETCH;
}

export function contentSetAction(content: ContentState): ContentSet {
  return {
    type: ContentActionTypes.CONTENT_SET,
    payload: content,
  };
}

export function contentFetchAction(): ContentFetch {
  return {
    type: ContentActionTypes.CONTENT_FETCH,
  };
}

export type ContentActions = ContentSet | ContentFetch;
