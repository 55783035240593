import * as Yup from "yup";
import {
  NAME_CANNOT_BE_BLANK,
  NAME_MUST_BE_LONGER_THAN,
  NAME_MUST_BE_SHORTER_THAN,
  SCHOOL_NAME_CANNOT_BE_BLANK,
  SCHOOL_NAME_MUST_BE_LONGER_THAN,
} from "../../helpers/messages";
import { URLRegex } from "../../helpers/schema";

export enum SubscriptionPlatform {
  Google = "google",
  Amazon = "amazon",
  Apple = "apple",
  Card = "card",
  Web = "web", // promotion
  Braintree = "braintree",
}

/**
 * API: client_api/lib/subscription/subscription.types.js
 */
export interface ClientDynamoUser {
  readonly username: string;
  readonly name: string;
  readonly lastName: string;
  readonly email: string;
  readonly country: string;
  readonly appVersion: string;
  readonly analyticsOptIn: boolean;
  readonly twilightUpdateOptIn: boolean;
  readonly partnerUpdateOptIn: boolean;
  readonly optOutOfSale: boolean;
  readonly referralCode: string;
  // school account
  readonly schoolDetails?: SchoolDetails;
  [key: string]: undefined | boolean | string | SchoolDetails;
}

export interface SchoolDetails {
  readonly schoolName: string;
  readonly schoolUrl: string;
  readonly schoolType: SchoolType;
  readonly city: string;
  readonly state?: string;
  readonly country: string;
  readonly approvalStatus: ApprovalStatus;
}

export enum SchoolType {
  private = "private",
  public = "public",
  charter = "charter",
}

export enum ApprovalStatus {
  pending = "pending",
  approved = "approved",
  expired = "expired",
  declined = "declined",
}

export type ISchoolSettingsUpdate = Partial<SchoolDetails>;
/**
 * API: client_api/lib/database/dynamo.types.js
 */
export interface ClientSubscriptionData {
  readonly subscriptionValid: boolean;
  readonly trialActive: boolean;
  readonly trialUsed: boolean;
  readonly startTimeMillis: number;

  readonly renewalTimeMillis: number;
  readonly renewalDetails: string;

  readonly platform: string;
  readonly codeRedeemed: string | null;
  readonly isSchoolSubscription?: boolean | null;
  readonly subscriptionId: string;
  readonly subscriptionType: string;
  readonly subscriptionStatusDetails: string;
  readonly isCancelled: boolean;

  readonly promotionDetails: string;
  readonly paymentDetails: string;
  readonly purchaseId: string;
}

/**
 * API: client_api/lib/cognito/devices.types.js
 */
export interface ClientCognitoDevice {
  readonly DeviceKey: string;
  readonly DeviceStatus: string;
  readonly DeviceName: string;
  readonly LastIpUsed: string;
  readonly LastAuthenticatedDate: number | null;
  readonly DeviceLastModifiedDate: number | null;
  readonly DeviceCreateDate: number | null;
}

/**
 * What POST /current-user accepts
 */
export interface ProfileUpdateParameters {
  name?: string;
  lastName?: string;
  email?: string; // only for admin mode
  country?: string;
  twilightUpdateOptIn?: boolean;
  analyticsOptIn?: boolean;
  partnerUpdateOptIn?: boolean;
  optOutOfSale?: boolean;
  // school account
  schoolDetails?: Partial<SchoolDetails>;
  // analytics
  mixpanelDistinctId?: string;
}

export const putCurrentUserPrivacySchema = Yup.object().shape({
  analyticsOptIn: Yup.boolean().required(),
  partnerUpdateOptIn: Yup.boolean().required(),
  twilightUpdateOptIn: Yup.boolean().required(),
  optOutOfSale: Yup.boolean().notRequired(),
});

export const putCurrentUserSettingsSchema = Yup.object().shape({
  email: Yup.string()
    .required(`This field can't be empty`)
    .email("Please enter valid email"),
  name: Yup.string()
    .required()
    .min(2, NAME_MUST_BE_LONGER_THAN)
    .max(25, NAME_MUST_BE_SHORTER_THAN)
    .required(NAME_CANNOT_BE_BLANK),
  lastName: Yup.string()
    .required()
    .min(2, NAME_MUST_BE_LONGER_THAN)
    .max(25, NAME_MUST_BE_SHORTER_THAN)
    .required(NAME_CANNOT_BE_BLANK),
});

export const putCurrentSchoolUserSettingsSchema = Yup.object().shape({
  schoolName: Yup.string()
    .min(3, SCHOOL_NAME_MUST_BE_LONGER_THAN)
    .required(SCHOOL_NAME_CANNOT_BE_BLANK),
  schoolUrl: Yup.string().matches(URLRegex, "Website must be a valid url."),
  schoolType: Yup.string().oneOf(["private", "public", "charter"]),
  city: Yup.string().required(),
  state: Yup.string().notRequired(),
  country: Yup.string(), // selected from drop down
});
