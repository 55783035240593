import { MenuItem } from "./types";

export const navigationMenuItems: MenuItem[] = [
  {
    id: "science",
    label: "Science",
    href: process.env.REACT_APP_WEB_URL + "/science",
  },
  {
    id: "parents",
    label: "Parents",
    href: process.env.REACT_APP_WEB_URL + "/parents",
  },
  {
    id: "schools",
    label: "Schools",
    href: process.env.REACT_APP_WEB_URL + "/moshi-for-schools",
  },
  {
    id: "articles",
    label: "Articles",
    href: process.env.REACT_APP_WEB_URL + "/articles",
  },
];

export const mobileNavigationMenuItems: MenuItem[] = [
  {
    id: "home",
    label: "Home",
    href: process.env.PUBLIC_URL,
  },
  {
    id: "articles",
    label: "Articles",
    href: process.env.REACT_APP_WEB_URL + "/articles",
  },
  {
    id: "schools",
    label: "Schools",
    href: process.env.REACT_APP_WEB_URL + "/moshi-for-schools",
  },
  {
    id: "parents",
    label: "Parents",
    href: process.env.REACT_APP_WEB_URL + "/parents",
  },
  {
    id: "science",
    label: "Science",
    href: process.env.REACT_APP_WEB_URL + "/science",
  },
];

export const mobileFooterMenuItems: MenuItem[] = [
  {
    id: "privacy",
    label: "Privacy policy",
    href: process.env.REACT_APP_WEB_URL + "/privacy-policy",
  },
  {
    id: "cookies",
    label: "Cookies",
    href: process.env.REACT_APP_WEB_URL + "/cookies",
  },
  {
    id: "terms",
    label: "T&Cs",
    href: process.env.REACT_APP_WEB_URL + "/terms-conditions",
  },
];
