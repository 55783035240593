import React, { useEffect, ComponentType } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { ApplicationState } from "clients/store";

import { Path } from "../../../store/path/constants";

function withRedirectToSchoolContent<T>(WrappedComponent: ComponentType) {
  return (props: T) => {
    const history = useHistory();
    const profile = useSelector((state: ApplicationState) => state.profile);
    useEffect(() => {
      if (
        profile.SubscriptionData &&
        profile.SubscriptionData.isSchoolSubscription &&
        profile.SubscriptionData.subscriptionValid
      ) {
        history.replace(Path.SONG_LIST_SCHOOLS);
      }
    }, [profile, history]);
    return <WrappedComponent {...props} />;
  };
}
export default withRedirectToSchoolContent;
