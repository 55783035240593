import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ApplicationState } from "./store";
import { Path } from "./store/path/constants";

import { canUpgrade } from "../shared/store/user/helpers";

import LandingPage from "./components/landing/LandingPage";
import SchoolsLanding from "./components/landing/SchoolsLanding";
import Profile from "./components/profile/Profile";
import Login from "./components/auth/login/Login";
import RequestPasswordReset from "./components/auth/resetPassword/ReqestResetPassword";
import ConfirmResetPassword from "./components/auth/resetPassword/ConfirmResetPassword";
import Signup from "./components/auth/signup/Signup";
import PaymentConfirmation from "./components/payment/PaymentConfirmation";
import CodeConfirmation from "./components/promotion/codes/CodeConfirmation";
import SchoolSignupConfirmation from "./components/schools/SchoolSignupConfirmation";
import Menu from "./components/player/menu/Menu";
import Player from "./components/player/Player";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import Payment from "./components/payment/Payment";
import RedeemCode from "./components/promotion/codes/RedeemCode";
import { PaymentUpdatePage } from "./components/payment/PaymentUpdate";
import { ErrorPage } from "shared/components/error/ErrorPage";

import withRedirectToSchoolContent from "./components/higherOrderComponents/schools/withRedirectToSchoolContent";

const ProtectedRoute = ({
  isAllowed,
  redirectTo = "/",
  hash,
  ...props
}: any) => {
  return isAllowed ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{ pathname: redirectTo, hash: hash, state: { from: props.path } }}
      from={props.path}
    />
  );
};

const Router = () => {
  const { auth, profile, promotion } = useSelector(
    (state: ApplicationState) => state
  );

  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  const hasAuth = !!auth.user; // logged in or temp user
  const isLoggedIn = auth.isLoggedIn;
  const hasPromotion = !!promotion.data;
  const hasProfile = !!profile; // logged in and api returned profile
  const hasSubscription = profile.SubscriptionData?.subscriptionValid;
  const userCanUpgrade = profile.SubscriptionData
    ? canUpgrade(profile.SubscriptionData)
    : false;

  const signupRedirect = () => {
    if (auth.targetLocation) {
      return auth.targetLocation;
    } else if (hasPromotion) {
      return Path.PAYMENT;
    } else {
      return Path.LANDING;
    }
  };
  const paymentRedirect = () => {
    if (hasSubscription && profile.SubscriptionData) {
      return Path.PROFILE;
    }
    return Path.SIGNUP;
  };

  if (!auth.isLoaded) {
    return (
      <div className="app-body" title="Loading data..">
        <LoadingIndicator />
        <span className="text-center">Loading...</span>
      </div>
    );
  }
  return (
    <React.Suspense fallback={<LoadingIndicator />}>
      <Switch>
        <ProtectedRoute
          path={Path.SIGNUP}
          exact={true}
          isAllowed={!hasAuth}
          redirectTo={signupRedirect()}
          component={Signup}
        />
        <ProtectedRoute
          path={Path.PAYMENT}
          exact={true}
          isAllowed={hasAuth && (!hasSubscription || userCanUpgrade)}
          redirectTo={paymentRedirect()}
          hash={"#subscription"}
          component={Payment}
        />
        <ProtectedRoute
          path={Path.PAYMENT_UPDATE}
          exact={true}
          isAllowed={isLoggedIn && hasAuth}
          redirectTo={Path.SIGNUP}
          component={PaymentUpdatePage}
        />
        <ProtectedRoute
          path={Path.PAYMENT_CONFIRMATION}
          exact={true}
          isAllowed={hasAuth}
          component={PaymentConfirmation}
        />
        <ProtectedRoute
          path={Path.RESET_PASSWORD}
          isAllowed={!isLoggedIn}
          exact={true}
          redirectTo={hasPromotion ? Path.PAYMENT : Path.LANDING}
          component={RequestPasswordReset}
        />
        <ProtectedRoute
          path={Path.RESET_PASSWORD_SET_NEW}
          isAllowed={!isLoggedIn}
          exact={true}
          redirectTo={hasPromotion ? Path.PAYMENT : Path.LANDING}
          component={ConfirmResetPassword}
        />
        <Route path={Path.LOGIN} component={Login} />
        <ProtectedRoute
          path={Path.PROFILE}
          isAllowed={isLoggedIn && hasProfile}
          redirectTo={Path.LOGIN}
          exact={true}
          component={Profile}
        />

        <Route
          path={Path.REDEEM_CODE_LANDING_LEGACY}
          component={LandingPage}
          exact={true}
        />
        <Route
          path={Path.REDEEM_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />
        <ProtectedRoute
          path={Path.REDEEM_CODE_INPUT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={RedeemCode}
        />
        <Route
          path={Path.REDEEM_CODE_CONFIRMATION}
          exact={true}
          component={CodeConfirmation}
        />

        <Route
          path={Path.PLUSH_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />
        <ProtectedRoute
          path={Path.PLUSH_CODE_INPUT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={RedeemCode}
        />
        <ProtectedRoute
          path={Path.PLUSH_CODE_PAYMENT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={Payment}
        />
        <Route
          path={Path.PLUSH_CODE_CONFIRMATION}
          exact={true}
          component={CodeConfirmation}
        />

        <Route path={Path.REFERRAL_CODE_LANDING} component={LandingPage} />

        <ProtectedRoute
          path={Path.PARTNER_CODE_INPUT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={RedeemCode}
        />
        <ProtectedRoute
          path={Path.PARTNER_CODE_PAYMENT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={Payment}
        />
        <Route
          path={Path.PARTNER_CODE_LANDING + "/:partner"}
          component={LandingPage}
        />

        <ProtectedRoute
          path={Path.SCHOOL_SIGNUP}
          exact={true}
          isAllowed={!hasAuth}
          redirectTo={auth.targetLocation ? auth.targetLocation : Path.PROFILE}
          component={Signup}
        />
        <Route
          path={Path.SCHOOL_CONFIRMATION}
          component={SchoolSignupConfirmation}
        />
        <Route path={Path.SONG_LIST_SCHOOLS} component={Menu} />

        <Route path={Path.SONG_SCHOOLS + "/:songId"} component={Player} />
        <Route
          path={Path.REDEEM_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />
        <Route
          path={Path.PLUSH_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />
        <Route path={Path.REFERRAL_CODE_LANDING} component={LandingPage} />
        <Route
          path={Path.PARTNER_CODE_LANDING + "/:partner"}
          component={LandingPage}
        />
        <Route
          path={Path.SCHOOL_LANDING}
          component={withRedirectToSchoolContent(SchoolsLanding)}
        />

        <Route path={Path.ERROR} component={ErrorPage} />
        <Route path={Path.LANDING} component={LandingPage} />
        <Redirect to={Path.LANDING} from={""} />
      </Switch>
    </React.Suspense>
  );
};

export default Router;
