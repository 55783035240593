import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { setupCache } from "axios-cache-adapter";

import { captureBreadcrumb, captureException, reportDialog } from "./logger";
import {
  ApiError,
  CodeRedeemError,
  ServerError,
  ValidationError,
} from "shared/helpers/errors";
import { ReportDialogOptions } from "@sentry/browser";

axios.defaults.baseURL =
  process.env.REACT_APP_API_ENDPOINT ||
  process.env.REACT_APP_CLIENTS_API_ENDPOINT;
axios.defaults.headers.common["Client-Version"] = "web-5.8.0";

const cache = setupCache({ maxAge: 0 });
const api = axios.create({
  baseURL: axios.defaults.baseURL,
  adapter: cache.adapter,
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
});

async function handleSuccess(a: AxiosResponse) {
  return a.data;
}

async function handleError(e: any) {
  if (e.response) {
    if (e.response.data) {
      const data = e.response.data;

      captureBreadcrumb({ data, message: data.message, category: "response" });

      if (data.reportId) {
        // tslint:disable-next-line:no-console
        console.info(
          "&#1F997; Looks like you've caught a bug: " + data.reportId
        );

        // report dialog for server error!
        const reportData: ReportDialogOptions = {
          eventId: data.reportId,
          dsn:
            process.env.REACT_APP_API_SENTRY_DSN ||
            process.env.REACT_APP_SENTRY_DSN,
        };
        reportDialog(reportData);
        throw new ServerError(data);
      }

      switch (data.code) {
        case "validation-error":
          throw new ValidationError(data);
        case "redemption-code-error":
        case "referral-code-error":
          throw new CodeRedeemError(data);
        default:
          throw new ApiError(data);
      }
    }
  }
  captureException(e, {
    message: "Unhandled API Exception",
    category: "response",
    extra: e.response,
  });
  throw e;
}

export async function get(path: string, data = {}, headers = {}, maxAge = 0) {
  return api
    .get(path, { params: data, headers, cache: { maxAge } })
    .then(handleSuccess)
    .catch(handleError);
}

export async function post(path: string, data = {}, headers = {}) {
  return axios
    .post(path, data, {
      headers,
    })
    .then(handleSuccess)
    .catch(handleError);
}

export async function put(path: string, data = {}, headers = {}) {
  return axios
    .put(path, data, {
      headers,
    })
    .then(handleSuccess)
    .catch(handleError);
}

export async function del(path: string, headers = {}) {
  return axios
    .delete(path, {
      headers,
    })
    .then(handleSuccess)
    .catch(handleError);
}

export default {
  post,
  get,
  put,
  del,
};
