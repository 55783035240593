import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { ApplicationState } from "clients/store";
import * as contentActions from "clients/store/content/actions";
import { Path } from "clients/store/path/constants";
import { CONTENT_TYPES } from "../../store/content/constants";
import { putFavorites } from "../../store/content/api";

import { events } from "clients/helpers/logger";
import { StoryData } from "clients/store/content";

import BackButton from "shared/components/controls/BackButton";
import StoryPlayer from "./storyPlayer/StoryPlayer";
import BreathingPlayer from "./storyPlayer/breathing/BreathingPlayer";
import StoryDetails from "./storyPlayer/StoryDetails";
import LessonPlans from "./storyPlayer/LessonPlans";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import { toast } from "react-toastify";

import withSchoolOnlyAccess from "../higherOrderComponents/schools/withSchoolOnlyAccess";
import withDesktopOnlyView from "../higherOrderComponents/layout/withDesktopOnlyView";

import "./Player.scss";

const Player = () => {
  const FALLBACK_BACKGROUND_URL = "/_default/background.jpeg";
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(contentActions.contentFetchAction());
  }, [dispatch]);

  const content = useSelector((state: ApplicationState) => state.content);
  const [storyData, setStoryData] = useState<StoryData>();
  const [baseUrl, setBaseUrl] = useState<string>();
  useEffect(() => {
    if (content.data) {
      const storyId = location.pathname.split("/").slice(-1)[0];
      setBaseUrl(`${content.data.baseUrl}/${storyId}/`);
      const storyDataForPath = content.data.storyData.find(
        (story) => story.id === storyId
      );
      if (storyDataForPath) {
        setStoryData(storyDataForPath);
      } else {
        toast.error("We could not find the content you are looking for");
        history.replace(Path.SONG_LIST_SCHOOLS);
      }
    }
  }, [content, storyData, baseUrl, location.pathname, history]);

  const isSongInFavoritesList = useCallback(
    (favorites?: string[]) => {
      if (favorites) {
        if (storyData?.id && favorites.includes(storyData.id)) {
          return true;
        }
      } else if (storyData?.id && content.data?.favorites) {
        if (content.data.favorites.has(storyData.id)) {
          return true;
        }
      }
      return false;
    },
    [content.data?.favorites, storyData?.id]
  );

  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [updatingFavorites, setUpdatingFavorites] = useState<boolean>(false);
  useEffect(() => {
    setIsFavorite(isSongInFavoritesList());
  }, [content, storyData, isSongInFavoritesList]);

  if (!baseUrl || !storyData) {
    return (
      <div className="app-body center player-page" id="player-page">
        <LoadingIndicator />
      </div>
    );
  }

  const onFavorite = async () => {
    try {
      if (!updatingFavorites) {
        setUpdatingFavorites(true);
        const favorites = await putFavorites(storyData.id);
        dispatch(
          contentActions.contentSetAction({
            ...content,
            data: {
              ...content.data!,
              favorites: new Set(favorites.storyIds),
            },
          })
        );
        const favoriteAdded = isSongInFavoritesList(favorites.storyIds);
        setIsFavorite(favoriteAdded);
        setUpdatingFavorites(false);

        if (favoriteAdded) {
          events.favorites.addedToFavorites(storyData);
          toast.success(`${storyData.name} has been added to favorites`);
        } else {
          events.favorites.removedFromFavorites(storyData);
          toast.success(`${storyData.name} has been removed from favorites`);
        }
      }
    } catch (e) {
      toast.error("Failed to add to favorites");
      setUpdatingFavorites(false);
    }
  };

  const sendSongPlayedEvent = () => {
    const query = qs.parse(location.search);
    // yes, qs gives undefined as a string...
    events.song.played(
      storyData,
      query.categoryId as string,
      query.selTagId === "undefined" ? undefined : (query.selTagId as string)
    );
  };
  const sendSongCompleteEvent = () => {
    const query = qs.parse(location.search);
    // yes, qs gives undefined as a string...
    events.song.completed(
      storyData,
      query.categoryId as string,
      query.selTagId === "undefined" ? undefined : (query.selTagId as string)
    );
  };

  return (
    <div className="app-body center player-page" id="player-page">
      <div className="component-box with-header">
        <div className="component-box-header">
          <BackButton />
        </div>
        <div className="container">
          {storyData.contentType === CONTENT_TYPES.BREATHE && (
            <BreathingPlayer
              baseUrl={baseUrl}
              storyData={storyData}
              onPlay={sendSongCompleteEvent}
              onEnded={sendSongCompleteEvent}
            />
          )}
          {storyData.contentType !== CONTENT_TYPES.BREATHE && (
            <StoryPlayer
              backgroundUrl={
                storyData.backgroundUrl
                  ? baseUrl + storyData.backgroundUrl
                  : content.data!.baseUrl + FALLBACK_BACKGROUND_URL
              }
              audioUrl={baseUrl + storyData.audioUrl}
              onPlay={sendSongPlayedEvent}
              onEnded={sendSongCompleteEvent}
            />
          )}

          <StoryDetails
            category={storyData.contentTypeName}
            name={storyData.name}
            narrator={storyData.narrator}
            description={storyData.description}
            artist={storyData.artist}
            isFavorite={isFavorite}
            onFavorite={onFavorite}
          />
        </div>

        {storyData.lessons && storyData.lessons.length > 0 && (
          <LessonPlans lessons={storyData.lessons} />
        )}
      </div>
    </div>
  );
};

export default withSchoolOnlyAccess(withDesktopOnlyView(Player));
