import React from "react";
import { getQuestionsAndAnswers } from "../../data/content";

import { SlidingTab } from "../../../../../shared/components/sliding-tab/SlidingTab";

import underlineImage from "../../images/underline.svg";

import "./Questions.scss";

const Questions = ({ showFreeTrial }: { showFreeTrial: boolean }) => {
  const questions = getQuestionsAndAnswers(showFreeTrial);
  return (
    <div className="questions-answered">
      <h2>ALL YOUR QUESTIONS ANSWERED</h2>
      <img src={underlineImage} alt="underline" />
      {questions.map((questionAndAnswers, i) => (
        <SlidingTab key={i} title={questionAndAnswers.question}>
          <ul>
            {questionAndAnswers.answers.map((answer, j) => (
              <li key={`answer-${i}-${j}`} style={questionAndAnswers.style}>
                {answer}
              </li>
            ))}
          </ul>
        </SlidingTab>
      ))}
    </div>
  );
};

export default Questions;
