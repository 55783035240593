export interface ContentApiResponse {
  readonly filterData: ApiFilterData[];
  readonly storyData: StoryData[];
  readonly storiesBaseUrl: string;
  readonly seriesData: SeriesData[];
}

export interface ContentApiFavoritesResponse {
  readonly storyIds: string[];
}

export interface ApiFilterData {
  readonly id: string; // eg. day
  readonly name: string;
  readonly filteredScreens: ApiFilteredScreen[];
}

interface ApiFilteredScreen {
  readonly id: string; // eg. meditations
  readonly name: string;
  readonly screenViews: ApiFilteredScreenView[];
}

interface ApiFilteredScreenView {
  readonly name: string; // only interested in All
  readonly storyIds: string[];
}

export interface ContentState {
  isLoading?: boolean;
  readonly error?: Error;
  readonly data?: Content;
}

export interface Content {
  readonly baseUrl: string;
  readonly storyData: StoryData[];
  readonly categoryData: CategoryData[]; // Merged storyIds from day + night all filters for each category
  readonly seriesData: SeriesData[];
  readonly selTags: Set<string>;
  readonly themes: Set<string>;
  readonly favorites: Set<string>;
}

export interface CategoryData {
  readonly id: string;
  readonly name: string;
  readonly storyIds: string[];
}

export interface SeriesData {
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly description: string;
  readonly landingImageUrl: string;
  readonly storyIds: string[];
}

export interface StoryData {
  readonly id: string;
  readonly artist: string;
  readonly tileUrl: string;
  readonly isIntro: boolean;
  readonly isNew: boolean;
  readonly tileWithTitleUrl: string;
  readonly timeOfDayMoment: string;
  readonly backgroundColor: string;
  readonly name: string;
  readonly contentType: string;
  readonly narrator: string;
  readonly audioUrl: string;
  readonly characterName: string;
  readonly characterUrl: string;
  readonly description: string;
  readonly duration: number;
  readonly ifFree: boolean;
  readonly backgroundUrl: string;
  readonly contentTypeName: string;
  readonly selTag?: string;
  // series
  readonly releaseDateMillis?: number;
  seriesName?: string;
  episode?: number;
  // lessons
  readonly lessons?: LessonPlanData[];
  // breathing
  readonly animationUrl?: string;
  readonly introAnimationUrl?: string;
  readonly introAudioUrl?: string;
  readonly themeName?: string;
}

export interface LessonPlanData {
  readonly lessonPlanId: string;
  readonly title: string;
  readonly ageGroup: LessonAgeGroup;
  readonly type: LessonPlanType;
  readonly mediaUrl: string;
}

export enum LessonAgeGroup {
  K_2 = "k-2",
  THREE_TO_FIVE = "3-5",
}

export enum LessonPlanType {
  OVERVIEW = "Overview",
  LESSON_PLAN = "Lesson plan",
  ACTIVITY = "Activity",
  ZIP = "Zip Bundle",
}

export enum ContentActionTypes {
  CONTENT_SET = "@@content/CONTENT_SET",
  CONTENT_FETCH = "@@content/CONTENT_FETCH",
}
