import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { authSetAction, AuthUser } from "shared/store/auth";
import { Path } from "clients/store/path/constants";
import { events, sendOptimizeActivate } from "clients/helpers/logger";
import { ApplicationState } from "clients/store";
import { loginCognitoUser } from "shared/store/auth/api";
import {
  signupCognitoUser,
  IAuthSignup,
  IAuthSignupSchool,
} from "shared/store/auth/api-signup";
import { toast } from "react-toastify";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import FlowIndicator from "../../flow/FlowIndicator";
import SignupLinks from "../../blocks/componentAfter/signupLinks";
import { SignupForm, SchoolSignupForm } from "./forms/SignupForm";

import "./Signup.scss";

const Signup = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const promotion = useSelector((state: ApplicationState) => state.promotion);
  useEffect(() => {
    if (promotion.data) {
      sendOptimizeActivate();
    }
  }, [promotion.data]);

  const signup = async (signupData: IAuthSignup | IAuthSignupSchool) => {
    try {
      const { user } = await signupCognitoUser(
        signupData,
        promotion.referral?.referralCode
      );
      const cognitoUser = await loginCognitoUser({
        username: (user as any).username as string,
        password: signupData.password,
      });
      cognitoUser.getUserAttributes((error, cognitoUserAttributes) => {
        if (error) {
          toast.error(error.message);
        } else {
          const authUserAttributes = cognitoUserAttributes?.reduce(
            (accumulator: {}, item: CognitoUserAttribute) => {
              return Object.assign(accumulator, {
                [item.getName()]: item.getValue(),
              });
            },
            {}
          );

          const authUser: AuthUser = {
            username: cognitoUser.getUsername(),
            name: authUserAttributes!.name,
            lastName: authUserAttributes!.family_name,
            email: authUserAttributes!.email,
            sub: authUserAttributes!.sub,
            groups: [],
          };

          events.signup.success();
          dispatch(
            authSetAction({
              isLoggedIn: true,
              user: authUser,
              targetLocation: isSchoolSignup
                ? "/schools/confirmation"
                : location?.state?.from,
            })
          );
        }
      });
    } catch (e) {
      toast.error(e.message);
      events.signup.error(e.message);
      throw e;
    }
  };
  const isSchoolSignup = location.pathname === Path.SCHOOL_SIGNUP;
  if (!promotion.data) {
    return (
      <div className="app-body center">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="app-body signup-page center">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">Create your account</h1>
          <FlowIndicator
            stepName={"Sign up"}
            step={1}
            stepCount={isSchoolSignup ? 2 : 3}
          />
        </div>

        <div className="text-center mb-1 regular font-bold">
          Already have an account?
          <NavLink to={Path.LOGIN} className="nav-link small font-bold">
            Log in
          </NavLink>
        </div>
        {isSchoolSignup ? (
          <SchoolSignupForm authSignup={signup} promotion={promotion} />
        ) : (
          <SignupForm authSignup={signup} promotion={promotion} />
        )}
      </div>
      {isSchoolSignup && (
        <div className="component-after">
          <h3 className="terms">*</h3>

          <p className="text-below">
            We will be in touch if we need additional information to verify that
            you are an educator. You will receive access to Moshi immediately
            upon subscribing and the subscription will end if we aren't able to
            verify your status as an educator.
          </p>
        </div>
      )}
      <SignupLinks />
    </div>
  );
};

export default Signup;
