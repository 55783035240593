import { ParsedQuery } from "query-string";
import { captureBreadcrumb } from "../logger";
import { Path, SpecialPromotionsByPath } from "../../store/path/constants";
import { Default } from "../constants";
import { PartnerInfo, ReferralInfo } from "../../store/promotion";
import { getPartnerInfo, getReferralInfo } from "../../store/promotion/api";
import { partnerRemember } from "../../store/promotion/local";
import { toast } from "react-toastify";

export function getPromotionSlugFromQueryString(
  query: ParsedQuery
): string | undefined {
  return query.promo as string;
}

export function getCountryOverrideFromQueryString(
  query: ParsedQuery
): string | undefined {
  const countryOverride = query.country;
  if (countryOverride) {
    captureBreadcrumb({
      message: `Changing country to ${countryOverride}`,
      category: "promotion",
    });
  }
  return countryOverride as string;
}

export async function getReferralDetailsFromQueryString(
  query: ParsedQuery
): Promise<ReferralInfo | undefined> {
  const referralCode = query.code;
  if (referralCode) {
    try {
      return await getReferralInfo(referralCode as string);
    } catch (e) {
      toast.error("Referral code does not exist");
    }
  }
}

export function shouldGetPromotionFromPath(path: string[]): boolean {
  return path[0] === "subscribe";
}

export function shouldGetReferralFromPath(path: string[]): boolean {
  return path[0] === "referral";
}

export function shouldGetPartnerFromPath(
  pathname: string,
  path: string[]
): boolean {
  return (
    (path[0] === "partners" &&
      !pathname.includes(Path.PARTNER_CODE_INPUT) &&
      !pathname.includes(Path.PARTNER_CODE_PAYMENT) &&
      !pathname.includes(Path.PARTNER_CODE_CONFIRMATION)) ||
    (path[0] === "schools" &&
      !Object.values(Path).includes(pathname as Path) &&
      !path.includes("play"))
  );
}

export function shouldGetSpecialPromoFromPath(path: string[]): boolean {
  const specialPromotionPaths = Object.keys(SpecialPromotionsByPath);
  return !!path.find(
    (value) =>
      specialPromotionPaths.includes(value) ||
      specialPromotionPaths.includes("/" + value)
  );
}

export function getPromotionSlugFromPath(path: string[]) {
  return path[1] || Default.SUBSCRIPTION_PLAN;
}

export async function getPartnerInfoFromPath(
  path: string[]
): Promise<PartnerInfo | undefined> {
  const partnerName = path[1];
  if (partnerName) {
    try {
      const partnerInfo = await getPartnerInfo(partnerName);
      partnerRemember(partnerName);
      return partnerInfo;
    } catch (e) {
      toast.error("Cannot find partner promotion");
    }
  }
}

export async function getReferralDetailsFromPath(
  path: string[]
): Promise<ReferralInfo | undefined> {
  const referralCode = path[1];
  if (referralCode) {
    try {
      return await getReferralInfo(referralCode);
    } catch (e) {
      toast.error("Referral code does not exist");
    }
  }
}

export function getSpecialPromotionSlugFromPath(path: string) {
  return SpecialPromotionsByPath["/" + path[0]];
}
