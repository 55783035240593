export const CATEGORY_IDS = {
  MOMENTS: "moments",
  MEDITATIONS: "meditations",
  STORIES: "stories",
  MUSIC: "music",
  SOUNDS: "sounds",
  SERIES: "series",
  BREATHE: "breathe",
  LULLABY: "lullaby",
  // special
  ALL: "all",
  FAVORITES: "favorites",
};
export const CONTENT_TYPES = {
  MOMENTS: "moment",
  MEDITATIONS: "meditation",
  STORIES: "story",
  MUSIC: "music",
  SOUNDS: "sound",
  SERIES: "series",
  BREATHE: "breathe",
};

export const SCHOOL_CONTENT_TYPES = { SCHOOLS_MOMENT: "schools_moment" };
export const CATEGORY_MAPPINGS = {
  [CATEGORY_IDS.MOMENTS]: SCHOOL_CONTENT_TYPES.SCHOOLS_MOMENT,
};
