import React from "react";
import { NavLink } from "react-router-dom";
import { searchLink } from "../../footer/data/links";

import search from "../images/search.svg";
import profile from "../images/profile.svg";
import logout from "../images/log-out.svg";

interface Props {
  isLoggedIn: boolean;
  menuOpen: boolean;
  callLogout: () => void;
  closeMenu: () => void;
}
const NavigationActionsMobile = ({
  isLoggedIn,
  menuOpen,
  callLogout,
  closeMenu,
}: Props) => {
  const closeMenuIfNeeded = () => {
    if (menuOpen) {
      closeMenu();
    }
  };
  return (
    <div className="navigation-mobile-action-menu">
      <a href={searchLink.href}>
        <img
          className="navigation-mobile-action-menu-item"
          src={search}
          alt="Search"
          onClick={closeMenuIfNeeded}
        />
      </a>
      {isLoggedIn && (
        <React.Fragment>
          <NavLink to="/profile">
            <img
              className="navigation-mobile-action-menu-item"
              src={profile}
              alt="Profile"
              onClick={closeMenuIfNeeded}
            />
          </NavLink>
          <img
            className="navigation-mobile-action-menu-item"
            src={logout}
            alt="Logout"
            onClick={callLogout}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default NavigationActionsMobile;
