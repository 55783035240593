import React from "react";
import { ClientSubscriptionData } from "shared/store/user/types";

const SubscriptionSection = ({
  subscriptionData,
  labels,
}: {
  subscriptionData: ClientSubscriptionData;
  labels: any;
}) => {
  if (!labels || !subscriptionData) {
    return (
      <div className="component-box">
        <div className="content subscription" />
      </div>
    );
  }
  return (
    <React.Fragment>
      <h1 className="mt-0 mb-1">Subscription</h1>
      <h3
        className="font-bold"
        dangerouslySetInnerHTML={{
          __html: labels.subscriptionStatusDetails,
        }}
      />
      {subscriptionData.subscriptionValid && (
        <div className="subscription-content">
          <div className="data-group">
            <p className="field-name">Platform </p>
            <div className="value">{labels.platform}</div>
          </div>
          {labels.startTimeDate && (
            <div className="data-group">
              <p className="field-name">Start date</p>
              <div className="value date">{labels.startTimeDate}</div>
            </div>
          )}
          <div className="data-group">
            <p className="field-name">Type</p>
            <div className="value">{labels.promotionDetails}</div>
          </div>
          {labels.renewalTimeDate && labels.renewalLabel && (
            <div className="data-group">
              <p className="field-name">{labels.renewalLabel} </p>
              <div className="value date">{labels.renewalTimeDate}</div>
            </div>
          )}

          {labels.codeDetails && (
            <div className="data-group">
              <p className="field-name">Code</p>
              <div className="value">{labels.codeDetails}</div>
            </div>
          )}
          {!subscriptionData.isCancelled && labels.renewalDetails && (
            <div className="data-group">
              <p className="field-name">Renewal details</p>
              <div className="value">{labels.renewalDetails}</div>
            </div>
          )}
          {labels.paymentDetails && (
            <div className="data-group">
              <p className="field-name">Payment details</p>
              <div className="value">{labels.paymentDetails}</div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default SubscriptionSection;
