import React from "react";
import { NotificationPayload } from "firebase/messaging";
import { events } from "../../helpers/logger";
import "./Notification.scss";

import defaultTile from "./images/defaultTile.jpeg";

interface Props {
  notification: NotificationPayload;
  link: string;
  show: boolean;
}
const Notification = ({ notification, link, show }: Props) => {
  const onClick = () => {
    show = false;
    events.notification.click(link);
    window.open(link, "_blank");
  };

  return (
    <div className={`notification ${show ? "" : "hidden"}`} onClick={onClick}>
      <span className="close-button" onClick={onClick} />
      <img
        className="notification-icon"
        src={notification.image ? notification.image : defaultTile}
        alt="notification"
        width={"105px"}
      />
      <div className="notification-content">
        <h3 className="notification-title">{notification.title}</h3>
        <p className="notification-body">{notification.body}</p>
      </div>
    </div>
  );
};

export default Notification;
