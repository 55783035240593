import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import braintree, { HostedFieldsTokenizePayload } from "braintree-web";
import { ApplicationState } from "../../store";
import { profileSetAction, ProfileState } from "../../store/profile";
import { Labels, promotionSelectAlternative } from "../../store/promotion";
import { Path } from "../../store/path/constants";
import { braintreeGetShouldVerifyWith3DS } from "../../store/payment/api/braintree";
import {
  braintreeCreateCustomer,
  braintreeSubscribe,
} from "../../store/profile/api/braintree";
import { canUpgrade, hasUsedTrial } from "../../../shared/store/user/helpers";
import { events } from "../../helpers/logger";

import {
  getTagManagerParametersFromLocalStorage,
  sendAdTrackingData,
} from "../../helpers/analytics/tagManager";
import { ALREADY_TAKEN_TRIAL } from "../../../shared/helpers/messages";

import { toast } from "react-toastify";
import FlowIndicator from "../flow/FlowIndicator";
import PromotionSelect from "./components/PromotionSelect";
import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";
import PaymentFormSecure from "./forms/PaymentFormSecure";
import PaymentForm from "./forms/PaymentForm";
import ModalAlert from "../../../shared/components/modal/ModalAlert";

import "./Payment.scss";

declare global {
  interface Window {
    // @ts-ignore
    // Braintree, particularly apple pay does not play nice with Typescript
    // ApplePaySession needs to come from the safari browser and be treated as such, but the braintree types are wrong.
    ApplePaySession: ApplePaySession;
  }
}

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dataLayer = window.dataLayer;

  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const { auth, profile, promotion } = useSelector(
    (state: ApplicationState) => state
  );

  const paymentFormSecure = useRef<PaymentFormSecure>(null);

  const [hasTrial, setHasTrial] = useState<boolean>(false);
  /**
   * User has trial
   */
  useEffect(() => {
    if (promotion.data) {
      if (promotion.data?.withTrial) {
        const result = !profile.SubscriptionData?.trialUsed;
        setHasTrial(result);
        dataLayer.push({ hasTrial: result });
      }
    }
  }, [
    dataLayer,
    promotion.data,
    promotion.data?.withTrial,
    profile.SubscriptionData?.trialUsed,
  ]);

  const [showTrialAlreadyUsedModal, setShowTrialAlreadyUsedModal] =
    useState<boolean>(false);
  /**
   * User cannot get a new trial via upgrade, we need to show a modal warning
   */
  useEffect(() => {
    if (profile.SubscriptionData && !canUpgrade(profile.SubscriptionData)) {
      setShowTrialAlreadyUsedModal(hasUsedTrial(profile.SubscriptionData));
    }
  }, [profile.SubscriptionData]);

  const [useSecurePayment, setUseSecurePayment] = useState<boolean>();
  /**
   * EU secure payment required
   */
  useEffect(() => {
    const checkShouldVerify = async () => {
      if (promotion.data) {
        setUseSecurePayment(
          await braintreeGetShouldVerifyWith3DS(promotion.data.countryCode)
        );
      }
    };
    checkShouldVerify().then();
  }, [promotion.data]);

  const [isInited, setIsInited] = useState<boolean>(false);
  /**
   * Everything is loaded and page is ready to be rendered
   */
  useEffect(() => {
    if (promotion.data) {
      if (auth.isLoggedIn && profile.UserData) {
        setIsInited(useSecurePayment !== undefined);
      }
    }
  }, [promotion.data, auth.isLoggedIn, profile.UserData, useSecurePayment]);

  const alternativePromotionSelected = useRef(false);
  const setAlternativePromotionSelected = (isAlternativeSelected: boolean) => {
    alternativePromotionSelected.current = isAlternativeSelected;
    const newPromotionPrice = isAlternativeSelected
      ? promotion.data!.alternativePromotion!.discountPrice
      : promotion.data!.discountPrice;
    paymentFormSecure.current!.updateAmount(newPromotionPrice);
    dispatch(promotionSelectAlternative(isAlternativeSelected));
  };

  if (!isInited || !promotion.data) {
    return (
      <div className="app-body" title="Loading data..">
        <LoadingIndicator />
      </div>
    );
  }

  const closeTrialUsedModal = () => {
    setShowTrialAlreadyUsedModal(false);
  };

  const onApplePay = (applePayInstance: braintree.ApplePay) => {
    try {
      if (applePayInstance) {
        // @ts-ignore
        const request = applePayInstance.createPaymentRequest({
          countryCode: promotion.data!.countryCode,
          currencyCode: promotion.data!.currencyIsoCode,
          total: {
            label: "Moshi Sleep",
            amount: promotion.data!.discountPrice,
          },
        });

        const session = new window.ApplePaySession(3, request);
        session.onvalidatemerchant = (event: any) => {
          applePayInstance
            // @ts-ignore
            .performValidation({
              validationURL: event.validationURL,
              displayName: "Moshi Sleep",
            })
            // @ts-ignore
            .then((merchantSession: any) => {
              session.completeMerchantValidation(merchantSession);
            })
            .catch((validationError: Error) => {
              session.abort();
              throw validationError;
            });
        };

        session.onpaymentauthorized = (event: any) => {
          applePayInstance
            // @ts-ignore
            .tokenize({ token: event.payment.token })
            // @ts-ignore
            .then((payload: any) => {
              onToken(payload);
              // Braintree session takes an enum of status' but we have to use the safari session instead while braintree's is broken
              session.completePayment({ status: 0 });
            })
            .catch((tokenizeError: Error) => {
              // Braintree session takes an enum of status' but we have to use the safari session instead while braintree's is broken
              session.completePayment({ status: 1 });
              throw tokenizeError;
            });
        };
        session.begin();
      } else {
        toast.error(
          "Make sure Apple Pay is set up correctly, or use a different payment method."
        );
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onToken = (token: HostedFieldsTokenizePayload) => {
    setProcessingPayment(true);

    const promoCode = promotion?.promoCode;
    const referrer = document.referrer;
    const referralCode = promotion.referral?.referralCode;

    const onSuccess = async (result: ProfileState) => {
      dispatch(profileSetAction(result));
      toast.success("Subscription activated");
      events.subscribe.activated(promotion!, hasTrial);
      if (hasTrial) {
        const utm_parameters = getTagManagerParametersFromLocalStorage();
        if (Object.keys(utm_parameters).length > 0) {
          await sendAdTrackingData({
            purchaseId: result.SubscriptionData!.purchaseId,
            utm_parameters: utm_parameters,
          });
        }
      }

      history.push(Path.PAYMENT_CONFIRMATION);
    };

    const onFail = (error: any) => {
      toast.error(error.message);
      events.subscribe.error(error);
      setProcessingPayment(false);
    };

    if (!promotion || !promotion.data) {
      toast.error("There was an error. Please reload the page and try again.");
      throw new Error("Missing Promotion ID");
    }

    // analytics
    events.subscribe.authorized(promotion);
    const promotionSlug = alternativePromotionSelected.current
      ? promotion.data.alternativePromotion!.promotionSlug
      : promotion.data.promotionSlug;

    if (!auth || !auth.isLoggedIn) {
      if (!auth || !auth.user || !auth.user.sub) {
        // this really should not happen
        toast.error(
          "There was an error. Please reload the page and try again."
        );
        const error = new Error("Missing userSub and UserData");
        events.subscribe.error(error);
        throw error;
      }

      // create a new user!
      braintreeCreateCustomer({
        paymentMethodNonce: token.nonce,
        promotionSlug,
        userSub: auth.user.sub,
        withTrial: promotion.data.withTrial,
        countryCode: promotion.country,
        codeUsed: promoCode,
        referrer: referrer.length > 0 ? referrer : undefined,
        referralUsed: referralCode,
      })
        .then(async (result) => {
          if (result.SubscriptionData) {
            await onSuccess(result);
          } else {
            events.subscribe.error(new Error("Did not get subscription"));
          }
        })
        .catch((e) => {
          onFail(e);
        });
    } else {
      // logged in user
      braintreeSubscribe({
        paymentMethodNonce: token.nonce,
        promotionSlug,
        withTrial: hasTrial,
        countryCode: promotion.country,
        codeUsed: promoCode,
        referrer: referrer.length > 0 ? referrer : undefined,
        referralUsed: referralCode,
      })
        .then(async (result) => {
          await onSuccess(result);
        })
        .catch((e) => {
          onFail(e);
        });
    }
  };

  const dataLayerLabels = dataLayer.find((element: object) =>
    Object.keys(element).includes("labels")
  );
  const labels: Labels = dataLayerLabels
    ? { ...promotion.data?.labels, ...dataLayerLabels.labels }
    : promotion.data?.labels;
  const alternativePromotionLabels = promotion.data?.alternativePromotion
    ? promotion.data.alternativePromotion.labels
    : undefined;
  const formLabels: Labels = {
    submitLabel: labels.pagePaymentStartButtonLabel,
    zipLabel: labels.pagePaymentZipLabel || "ZIP",
  };

  return (
    <div className="app-body center payment-page">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">{labels.pagePaymentHeader}</h1>
          <FlowIndicator
            stepName={"Subscribe"}
            step={promotion.data.promotionSlug === "sleepies" ? 3 : 2}
          />
        </div>
        {alternativePromotionLabels ? (
          <React.Fragment>
            <PromotionSelect
              promotionLabels={{
                pagePaymentPlanButtonTitle: labels!.pagePaymentPlanButtonTitle,
                pagePaymentPlanButtonBadge: labels!.pagePaymentPlanButtonBadge,
                pagePaymentPlanButtonBody: labels!.pagePaymentPlanButtonBody,
              }}
              alternativePromotionLabels={{
                pagePaymentPlanButtonTitle:
                  alternativePromotionLabels!.pagePaymentPlanButtonTitle,
                pagePaymentPlanButtonBadge:
                  alternativePromotionLabels!.pagePaymentPlanButtonBadge,
                pagePaymentPlanButtonBody:
                  alternativePromotionLabels!.pagePaymentPlanButtonBody,
              }}
              setAltPromotionSelected={setAlternativePromotionSelected}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1
              className="text-center top-title"
              dangerouslySetInnerHTML={{ __html: labels.pagePaymentTopTitle }}
            />

            {labels.pagePaymentNoChargeTodayLabel && (
              <h1 className="text-center top-title">
                {labels.pagePaymentNoChargeTodayLabel}
              </h1>
            )}
          </React.Fragment>
        )}
        {!processingPayment && (
          <p
            className="text-center subtitle"
            dangerouslySetInnerHTML={{ __html: labels.pagePaymentTopSubtitle }}
          />
        )}
        <div
          className={`with-loading-block ${processingPayment ? "loading" : ""}`}
        >
          <div className="loading-block text-center">
            <div>Please wait.</div>
            <div>
              We are processing your payment, this can take a few minutes.
            </div>
            <LoadingIndicator />
          </div>
          <React.StrictMode>
            <React.Suspense fallback={<LoadingIndicator />}>
              {useSecurePayment === true && (
                <PaymentFormSecure
                  ref={paymentFormSecure}
                  onToken={onToken}
                  onApplePay={onApplePay}
                  labels={formLabels}
                  threeDSecureInfo={{
                    amount: promotion.data!.discountPrice,
                    email: profile.UserData?.email,
                    merchantAccount: promotion.data!.merchantAccountSlug,
                  }}
                />
              )}
              {useSecurePayment === false && (
                <PaymentForm
                  onToken={onToken}
                  onApplePay={onApplePay}
                  labels={formLabels}
                />
              )}
            </React.Suspense>
          </React.StrictMode>
          <p className="text-center text-below">{labels.pagePaymentBelowCTA}</p>
        </div>
      </div>
      <div className="component-after">
        <h3 className="terms">Terms and conditions</h3>
        <p
          className="text-below"
          dangerouslySetInnerHTML={{ __html: labels.pageSubscribeTerms }}
        />
      </div>
      <ModalAlert
        isOpen={showTrialAlreadyUsedModal}
        title={"Oooops"}
        description={ALREADY_TAKEN_TRIAL}
        actionText={"Continue Without Trial"}
        close={closeTrialUsedModal}
      />
    </div>
  );
};

export default Payment;
