import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "clients/store";
import { authSetAction } from "shared/store/auth";
import { sendOptimizeActivate, events } from "clients/helpers/logger";
import { Path } from "clients/store/path/constants";
import { PromotionSlug } from "shared/store/campaigns/types";
import {
  cognitoUserToAuthState,
  loginCognitoUser,
  PasswordResetRequired,
  IAuthLogin,
} from "shared/store/auth/api";
import { toast } from "react-toastify";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import LoginForm from "shared/components/forms/auth/LoginForm";

import "./Login.scss";
import LoginLinks from "../../blocks/componentAfter/LoginLinks";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state: ApplicationState) => state.auth);
  const profile = useSelector((state: ApplicationState) => state.profile);
  const promotion = useSelector((state: ApplicationState) => state.promotion);
  useEffect(() => {
    if (promotion.data) {
      sendOptimizeActivate();
    }
  }, [promotion.data]);

  const [redirectLocation, setRedirectLocation] = useState("");
  useEffect(() => {
    if (profile.SubscriptionData) {
      if (profile.SubscriptionData.isSchoolSubscription) {
        setRedirectLocation(Path.SONG_LIST_SCHOOLS);
      } else if (profile.SubscriptionData.subscriptionValid) {
        setRedirectLocation(Path.PROFILE);
      } else if (promotion.data) {
        const promotionSlug = promotion.data.promotionSlug;
        if (promotionSlug === PromotionSlug.REDEEM_CODE) {
          setRedirectLocation(Path.REDEEM_CODE_INPUT);
        } else if (promotionSlug === PromotionSlug.PLUSH_CODE) {
          setRedirectLocation(Path.PLUSH_CODE_INPUT);
        } else {
          setRedirectLocation(Path.PAYMENT);
        }
      } else if (location.state && location.state.from) {
        setRedirectLocation(location.state.from);
      } else {
        setRedirectLocation(Path.PAYMENT);
      }
    }
  }, [profile.SubscriptionData, promotion, location.state]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      if (profile.SubscriptionData && redirectLocation) {
        history.push(redirectLocation);
      }
    }
  }, [auth.isLoggedIn, profile.SubscriptionData, redirectLocation, history]);

  const login = async (loginData: IAuthLogin) => {
    try {
      const cognitoUser = await loginCognitoUser(loginData);
      const authState = await cognitoUserToAuthState(cognitoUser);
      dispatch(authSetAction({ isLoaded: true, ...authState }));
      events.login.success();
    } catch (e) {
      events.login.error(e.message);
      if (e instanceof PasswordResetRequired) {
        toast.warn(e.message);
        history.push(Path.RESET_PASSWORD);
        return;
      } else {
        toast.error(e.message);
      }
    }
  };

  if (auth.isLoggedIn) {
    if (!profile.SubscriptionData || !redirectLocation) {
      return (
        <div className="app-body">
          <LoadingIndicator />
        </div>
      );
    }
  }
  return (
    <div className={`app-body box login-page center`}>
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">Login</h1>
          <p className="subtitle">Login to your Moshi account</p>
        </div>
        <div className="mt-1">
          <LoginForm fieldType="email" login={login} />
        </div>
      </div>
      <div className="hide-on-mobile">
        <LoginLinks />
      </div>
    </div>
  );
};

export default Login;
