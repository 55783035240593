import { Reducer } from "redux";
import { ProfileActions } from "./actions";
import { ProfileActionsTypes, ProfileState } from "./types";
import { events } from "clients/helpers/logger";
import { AuthActions, AuthActionsTypes, AuthState } from "shared/store/auth";
import { hook } from "../../helpers/hooks";

const initialProfileState: ProfileState = {
  isLoading: false,
  userSub: undefined,
  UserData: undefined,
  SubscriptionData: undefined,
};

const profileReducer: Reducer<ProfileState, ProfileActions> = (
  state = initialProfileState,
  action
) => {
  switch (action.type) {
    case ProfileActionsTypes.PROFILE_SET:
      /**
       * Google Optimize might want to give the
       *  user some other text in the profile.
       */
      const profile = hook("profile-set", action.payload);
      events.login.profile(profile);

      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};

const initialAuthState: AuthState = {
  isLoggedIn: false,
  user: undefined,
};

const authReducer: Reducer<AuthState, AuthActions> = (
  state = initialAuthState,
  action
) => {
  switch (action.type) {
    case AuthActionsTypes.AUTH_SET:
      const auth = action.payload;
      events.login.auth(auth);
      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};

export { profileReducer, authReducer };
