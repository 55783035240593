export interface PagesState {
  readonly [pathname: string]: PageData;
}

export interface PageData {
  readonly scrollData: PageScrollData;
  readonly filterData?: PageFilterData;
}

export interface PageScrollData {
  readonly yPosition: number;
  readonly xPosition?: number;
}

export interface PageFilterData {
  readonly selectedFilterId: string;
  readonly selectedSubFilterId?: string;
  readonly selectedNarratorFilterId?: string;
}

export enum PagesActionTypes {
  PAGES_DATA_SET = "@@pages/PAGES_DATA_SET",
}
