import React from "react";
import withRenderOnResize from "../../../higherOrderComponents/layout/withRenderOnResize";

import "./TryMoshiPhoneImage.scss";

import topLines from "../../images/lines-top.svg";
import bottomLines from "../../images/lines-bottom.svg";
import phoneMobile from "../../images/phone.svg";
import phoneDesktop from "../../images/phone-desktop.svg";
import phoneShadows from "../../images/shadows.svg";

interface Props {
  shouldUseMobileLayout?: boolean;
}

const TryMoshiPhoneImage = ({ shouldUseMobileLayout }: Props) => {
  return (
    <React.Fragment>
      <img className="try-moshi-sun-lines top" src={topLines} alt="Sun lines" />
      <img
        className="try-moshi-phone-image"
        src={shouldUseMobileLayout ? phoneMobile : phoneDesktop}
        alt="Home screen"
      />
      <img
        className="try-moshi-phone-shadows"
        src={phoneShadows}
        alt="Shadows"
      />
      <img
        className="try-moshi-sun-lines bottom"
        src={bottomLines}
        alt="Sun lines"
      />
    </React.Fragment>
  );
};

export default withRenderOnResize(TryMoshiPhoneImage);
