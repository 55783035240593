import React from "react";
import _ from "lodash";

import { TableColumn } from "./types";

const TableBody = ({
  columns,
  data,
  onRowSelected,
}: {
  columns: TableColumn[];
  data: any[];
  onRowSelected?: (item: any) => void;
}) => {
  const renderCell = (item: any, column: TableColumn) => {
    if (column.content) {
      return column.content(item);
    } else if (column.path) {
      return _.get(item, column.path);
    }
  };
  const createKey = (item: any, column: TableColumn): string => {
    return item.id + (column.path || column.key);
  };

  const handleClick = (item: any) => {
    if (onRowSelected) {
      onRowSelected(item);
    }
  };
  return (
    <tbody>
      {data.map((item: any, i: number) => (
        <tr key={i} id={"row-" + item.id} onClick={() => handleClick(item)}>
          {columns.map((column: TableColumn) => (
            <td key={createKey(item, column)}>{renderCell(item, column)}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
