import React, { useState } from "react";
import SettingsForm from "shared/components/forms/users/SettingsForm";
import {
  ClientDynamoUser,
  ProfileUpdateParameters,
} from "shared/store/user/types";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";

const SettingsSection = ({
  userData,
  updateUserData,
}: {
  userData: ClientDynamoUser;
  updateUserData: (u: ProfileUpdateParameters) => Promise<void>;
}) => {
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  return (
    <div className="component-box">
      {!userData ? (
        <LoadingIndicator />
      ) : (
        <SettingsForm
          editMode={editMode}
          switchMode={toggleEditMode}
          userData={userData}
          userDataUpdate={updateUserData}
        />
      )}
    </div>
  );
};

export default SettingsSection;
