import adjust from "@adjustcom/adjust-web-sdk";
import { captureMessage } from "../logger";
import { Severity } from "@sentry/types";

class Adjust {
  private static instance: Adjust;

  public static uuid: string;

  private static init() {
    adjust.initSdk({
      appToken: process.env.REACT_APP_ADJUST_APP_ID || "",
      environment: process.env.REACT_APP_ADJUST_ENVIRONMENT as
        | "production"
        | "sandbox",
      logLevel: process.env.REACT_APP_ADJUST_LOG_LEVEL as
        | "verbose"
        | "none"
        | "error"
        | "warning"
        | "info",
      attributionCallback: (e, attribution) => {
        console.log(e);
        console.log(attribution);
      },
    });
  }

  public static getInstance() {
    if (!Adjust.instance) {
      Adjust.instance = new Adjust();
    }
    return Adjust.instance;
  }

  private constructor() {
    Adjust.init();
  }

  private static setUUID() {
    try {
      // open db
      const open = indexedDB.open("adjust-sdk", 1);
      open.onsuccess = function () {
        // get the resulting db
        const db = open.result;
        // create transaction to open store
        const transaction = db.transaction("as", "readonly");
        // check the store we are looking for exists
        if (transaction.objectStoreNames.contains("as")) {
          // get the object store
          const objectStore = transaction.objectStore("as");
          // and all the keys
          const objectStoreRequest = objectStore.getAll();
          // inside the as store u is the key for webId
          objectStoreRequest.onsuccess = () => {
            Adjust.uuid = objectStoreRequest.result[0].u;
          };
        }
      };
    } catch (e) {
      captureMessage("failed to get Adjust web_uuid", { level: Severity.Log });
    }
  }

  public static getUUD() {
    if (Adjust.uuid) {
      return this.uuid;
    } else {
      this.setUUID();
      return this.uuid;
    }
  }
}

export default Adjust;
