import React from "react";

import AudioPlayer from "react-h5-audio-player";

import "./StoryPlayer.scss";

import play from "./icons/Play@1.5x.svg";
import pause from "./icons/Pause@1.5x.svg";
import rewind from "./icons/Backwards@1.5x.svg";
import forward from "./icons/Forwards@1.5x.svg";
import loopOn from "./icons/LoopOn@1.5x.svg";
import loopOff from "./icons/LoopOff@1.5x.svg";
import volumeOn from "./icons/VolumeOn@1.5x.svg";
import volumeOff from "./icons/VolumeOff@1.5x.svg";

const StoryPlayer = ({
  backgroundUrl,
  audioUrl,
  onPlay,
  onEnded,
}: {
  backgroundUrl: string;
  audioUrl: string;
  onPlay?: () => void;
  onEnded?: () => void;
}) => {
  const customIcons = {
    play: React.createElement("img", {
      id: "play",
      src: play,
    }),
    pause: React.createElement("img", {
      id: "pause",
      src: pause,
    }),
    rewind: React.createElement("img", {
      id: "rewind",
      src: rewind,
    }),
    forward: React.createElement("img", {
      id: "forward",
      src: forward,
    }),
    loop: React.createElement("img", {
      id: "loop",
      src: loopOn,
    }),

    loopOff: React.createElement("img", {
      id: "loop-off",
      src: loopOff,
    }),
    volume: React.createElement("img", {
      id: "volume",
      src: volumeOn,
    }),
    volumeMute: React.createElement("img", {
      id: "mute",
      src: volumeOff,
    }),
  };

  return (
    <div className="player">
      <img
        className="background"
        src={backgroundUrl}
        alt="moshi background"
        width={500}
      />
      <AudioPlayer
        autoPlay={false}
        showFilledVolume={true}
        customIcons={customIcons}
        progressJumpStep={15000}
        src={audioUrl}
        onPlay={onPlay}
        onEnded={onEnded}
      />
    </div>
  );
};

export default StoryPlayer;
