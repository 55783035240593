import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { ValidationError } from "shared/helpers/errors";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import FieldError from "shared/components/forms/elements/fieldError";

import "./RedeemCodeForm.scss";

interface RedeemCodeFields {
  promoCode: string;
}
interface RedeemCodeProps {
  onRedeem: (code: string) => Promise<void>;
}

const RedeemCodeFormTemplate = (props: FormikProps<RedeemCodeFields>) => {
  const { errors, touched, isSubmitting } = props;
  return (
    <Form>
      <div className="form-group">
        <Field
          id="promoCode"
          name="promoCode"
          type="text"
          className={
            errors.promoCode && touched.promoCode
              ? "form-control promo-code with-error"
              : "form-control promo-code"
          }
          maxLength={20}
          placeholder="Promo Code"
        />
        {errors.promoCode && touched.promoCode && (
          <FieldError message={errors.promoCode} />
        )}
      </div>
      <div className={`form-group with-loading-block`}>
        {(isSubmitting && <LoadingIndicator />) || (
          <button className="btn action filled" type="submit">
            Continue
          </button>
        )}
      </div>
    </Form>
  );
};

const RedeemCodeForm = withFormik<RedeemCodeProps, RedeemCodeFields>({
  displayName: "RedeemCodeForm",
  handleSubmit: (
    values: RedeemCodeFields,
    formikBag: FormikBag<RedeemCodeProps, RedeemCodeFields>
  ) => {
    formikBag.props
      .onRedeem(values.promoCode.toUpperCase())
      .catch((e) => {
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: () => {
    return {
      promoCode: "",
    };
  },
  validationSchema: Yup.object().shape({
    promoCode: Yup.string()
      .min(6, "Promo code needs to be at least 6 characters")
      .max(20, "Promo code can't be longer than 20 characters")
      .required("This is a required field"),
  }),
})(RedeemCodeFormTemplate);

export default RedeemCodeForm;
