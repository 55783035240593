import React from "react";

const DownloadButton = ({
  className,
  link,
  id,
  image,
  imageAltText = "",
}: {
  className?: string;
  link: string;
  id: string;
  image?: any;
  imageAltText: string;
}) => {
  const content = () => {
    if (image) {
      return <img src={image} alt={imageAltText} />;
    } else {
      return imageAltText;
    }
  };
  return (
    <div>
      <a
        className={"ga-event " + className}
        target="_blank"
        id={id}
        rel="noopener noreferrer"
        href={link}
      >
        {content()}
      </a>
    </div>
  );
};

export default DownloadButton;
