import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { toast } from "react-toastify";

import ProfileApi from "../../store/profile/api/user";

import * as profileActions from "../../store/profile/actions";
import {
  ProfileUpdateParameters,
  ClientCognitoDevice,
  ClientDynamoUser,
} from "../../store/profile";
import { ApplicationState } from "../../store";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import PrivacyAndNotificationsSection from "shared/components/users/PrivacyAndNotifications";
import DevicesSection from "shared/components/users/sections/DevicesSection";
import SchoolSection from "../../../shared/components/users/sections/SchoolSection";
import SubscriptionSection from "./sections/SubscriptionSection";
import SettingsSection from "./sections/SettingsSection";

import "shared/components/users/User.scss";

const Profile = ({ history }: RouteChildrenProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(profileActions.profileFetchAction());
    dispatch(profileActions.profileDevicesFetchAction());
  }, [dispatch]);

  const profile = useSelector((state: ApplicationState) => state.profile);

  const [userData, setUserData] = useState<ClientDynamoUser>();
  const [deviceList, setDeviceList] = useState<ClientCognitoDevice[]>();
  const [isSchoolSubscription, setIsSchoolSubscription] = useState(false);
  useEffect(() => {
    if (profile) {
      if (profile.UserData) {
        setUserData(profile.UserData);
      }

      if (profile.DeviceList) {
        setDeviceList(profile.DeviceList);
      }
      if (profile.SubscriptionData) {
        setIsSchoolSubscription(
          !!profile.SubscriptionData.isSchoolSubscription
        );
      }
    }
  }, [profile]);

  const updateUserData = async (updateParameters: ProfileUpdateParameters) => {
    try {
      const profileState = await ProfileApi.putCurrentUser(updateParameters);
      if (
        updateParameters.email &&
        userData!.email !== updateParameters.email
      ) {
        toast.success("Email changed successfully.");
      }
      dispatch(profileActions.profileSetAction(profileState));
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  };

  const deleteDevice = (deviceId: string) => {
    dispatch(profileActions.profileDeviceDeleteAction(deviceId));
  };

  const redirectToPath = (path: string) => {
    history.push(path);
  };

  if (!userData) {
    return (
      <div className="app-body">
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div className="app-body center user-page">
      <SettingsSection userData={userData} updateUserData={updateUserData} />
      <PrivacyAndNotificationsSection
        userData={userData}
        userDataUpdate={updateUserData}
      />
      {isSchoolSubscription && (
        <SchoolSection userData={userData} updateUserData={updateUserData} />
      )}
      <section id="subscription" />
      <SubscriptionSection redirect={redirectToPath} />
      <DevicesSection devices={deviceList} deleteDevice={deleteDevice} />
    </div>
  );
};

export default Profile;
