import React, { useMemo } from "react";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import CountryList from "react-select-country-list";
import {
  IAuthSignup,
  IAuthSignupSchool,
  authSignupSchema,
  authSignupSchoolSchema,
} from "shared/store/auth/api-signup";
import { ValidationError } from "shared/helpers/errors";
import { PromotionState } from "clients/store/promotion";
import { ReCaptcha } from "../../../reCaptcha/reCaptcha";
import FieldError from "shared/components/forms/elements/fieldError";
import FormDropdown from "../../../../../shared/components/forms/elements/FormDropdown";
import FieldButton from "../../../../../shared/components/forms/elements/FieldButton";

type FieldKey =
  | "name"
  | "lastName"
  | "email"
  | "schoolName"
  | "schoolUrl"
  | "schoolType"
  | "schoolCity"
  | "schoolState"
  | "schoolCountry"
  | "country"
  | "password"
  | "reCaptchaToken";
interface SignupFormProps {
  authSignup: (fields: IAuthSignup | IAuthSignupSchool) => Promise<void>;
  promotion: PromotionState;
}

const reCaptchaReference: React.RefObject<ReCaptcha> = React.createRef();
const SignupFormTemplate = (
  props: SignupFormProps & FormikProps<IAuthSignup | IAuthSignupSchool>
) => {
  const countryList = useMemo(() => CountryList().getData(), []);
  const schoolTypes = useMemo(
    () => [
      { id: "private", label: "Private" },
      { id: "public", label: "Public" },
      { id: "charter", label: "Charter" },
    ],
    []
  );
  const { errors, values, touched, isSubmitting } = props;

  const handleOnChangeComplete = (token: string) => {
    values.reCaptchaToken = token;
  };
  const togglePartnerUpdate = () => {
    values.partnerUpdateOptIn = !values.partnerUpdateOptIn;
  };
  const toggleTwilightUpdate = () => {
    values.twilightUpdateOptIn = !values.twilightUpdateOptIn;
  };
  const showError = (fieldKey: FieldKey): boolean => {
    return !!(errors[fieldKey] && touched[fieldKey]);
  };

  return (
    <Form>
      <div className="form-group">
        <Field
          id="name"
          name="name"
          type="text"
          className={
            showError("name") ? "form-control with-error" : "form-control"
          }
          maxLength={25}
          placeholder="First name"
        />
        {showError("name") && <FieldError message={errors.name} />}
      </div>
      <div className="form-group">
        <Field
          id="lastName"
          name="lastName"
          type="text"
          className={
            errors.lastName && touched.lastName
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={25}
          placeholder="Last name"
        />
        {showError("lastName") && <FieldError message={errors.lastName} />}
      </div>
      {(props as SignupFormProps & FormikProps<IAuthSignupSchool>).values
        .schoolName !== undefined && (
        <div className="form-group">
          <Field
            id="schoolName"
            name="schoolName"
            type="text"
            className={
              showError("schoolName")
                ? "form-control with-error"
                : "form-control"
            }
            maxLength={50}
            placeholder="School name"
          />
          {showError("schoolName") && (
            <FieldError message={errors.schoolName} />
          )}
        </div>
      )}
      {(props as SignupFormProps & FormikProps<IAuthSignupSchool>).values
        .schoolUrl !== undefined && (
        <div className="form-group">
          <Field
            id="schoolUrl"
            name="schoolUrl"
            type="select"
            className={
              showError("schoolUrl")
                ? "form-control with-error"
                : "form-control"
            }
            maxLength={50}
            placeholder="School website"
          />
          {showError("schoolUrl") && <FieldError message={errors.schoolUrl} />}
        </div>
      )}
      {(props as SignupFormProps & FormikProps<IAuthSignupSchool>).values
        .schoolType !== undefined && (
        <div className="form-group">
          <FormDropdown
            id={"schoolType"}
            name={"schoolType"}
            noSelection={"Select a school Type"}
            startingValue={values.schoolType as string}
            options={schoolTypes}
          />
          {errors.schoolType && touched.schoolType && (
            <FieldError message={errors.schoolType} />
          )}
        </div>
      )}

      {(props as SignupFormProps & FormikProps<IAuthSignupSchool>).values
        .schoolCity !== undefined && (
        <div className="form-group">
          <Field
            id="schoolCity"
            name="schoolCity"
            type="select"
            className={
              showError("schoolCity")
                ? "form-control with-error"
                : "form-control"
            }
            maxLength={50}
            placeholder="City"
          />
          {showError("schoolCity") && (
            <FieldError message={errors.schoolCity} />
          )}
        </div>
      )}
      {(props as SignupFormProps & FormikProps<IAuthSignupSchool>).values
        .schoolState !== undefined && (
        <div className="form-group">
          <Field
            id="schoolState"
            name="schoolState"
            type="select"
            className={
              showError("schoolState")
                ? "form-control with-error"
                : "form-control"
            }
            maxLength={50}
            placeholder="State"
          />
          {showError("schoolState") && (
            <FieldError message={errors.schoolWebsite} />
          )}
        </div>
      )}
      <div className="form-group">
        <Field
          id="email"
          name="email"
          type="email"
          className={
            errors.email && touched.email
              ? "form-control with-error"
              : "form-control "
          }
          maxLength={128}
          placeholder="Email Address"
        />
        {showError("email") && <FieldError message={errors.email} />}
      </div>

      <div className="form-group">
        <Field
          name="password"
          type="password"
          className={
            showError("password") ? "form-control with-error" : "form-control"
          }
          maxLength={128}
          placeholder="Password"
        />
        {showError("password") && <FieldError message={errors.password} />}
      </div>

      {(props as SignupFormProps & FormikProps<IAuthSignupSchool>).values
        .schoolCountry !== undefined && (
        <div className="form-group">
          <FormDropdown
            id={"schoolCountry"}
            name={"schoolCountry"}
            noSelection={"Select a country"}
            startingValue={values.schoolCountry as string}
            options={countryList}
          />
          {showError("schoolCountry") && (
            <FieldError message={errors.schoolCountry} />
          )}
        </div>
      )}
      <div className="form-group mt-2">
        <label className="checkbox" htmlFor="twilightUpdateOptIn">
          <span onClick={toggleTwilightUpdate}>
            <Field
              id="twilightUpdateOptIn"
              name="twilightUpdateOptIn"
              component="input"
              type="checkbox"
              checked={values.twilightUpdateOptIn}
            />
            <span />
          </span>
          Sign up to receive emails and updates from us about new app features
          and offers
        </label>
      </div>

      <div className="form-group">
        <label className="checkbox" htmlFor="partnerUpdateOptIn">
          <span onClick={togglePartnerUpdate}>
            <Field
              id="partnerUpdateOptIn"
              name="partnerUpdateOptIn"
              component="input"
              type="checkbox"
              checked={values.partnerUpdateOptIn}
            />
            <span />
          </span>
          Sign up to receive emails and offers from our friends and partners
          that we think you will love
        </label>
      </div>

      <div className="re-captcha">
        <ReCaptcha
          ref={reCaptchaReference}
          onChangeComplete={handleOnChangeComplete}
        />

        <Field
          name="reCaptchaToken"
          value={values.reCaptchaToken}
          type="hidden"
        />
        {showError("reCaptchaToken") && (
          <div className="error-message">{errors.reCaptchaToken}</div>
        )}
      </div>
      <FieldButton text={"Continue"} withLoading={isSubmitting} />
    </Form>
  );
};

function precheckedAnalytics(props: SignupFormProps) {
  let prechecked = true;
  let country = "";
  if (props.promotion && props.promotion.data) {
    country = props.promotion.data.countryCode;
    if (props.promotion.data.groups) {
      if (props.promotion.data.groups.indexOf("gdpr") > -1) {
        prechecked = false;
      }
    }
  }
  return { prechecked, country };
}

export const SignupForm = withFormik<SignupFormProps, IAuthSignup>({
  displayName: "SignupForm",
  handleSubmit: (
    values: IAuthSignup,
    formikBag: FormikBag<SignupFormProps, IAuthSignup>
  ) => {
    formikBag.props
      .authSignup({
        ...values,
        email: values.email.toLocaleLowerCase(),
      } as IAuthSignup)
      .catch((e) => {
        // fill rejected fields
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
        if (reCaptchaReference.current) {
          reCaptchaReference.current.reset();
        }
      })
      .finally(() => {
        formikBag.setSubmitting(false);
        values.reCaptchaToken = "";
      });
  },
  mapPropsToValues: (props: SignupFormProps): IAuthSignup => {
    let { prechecked, country } = precheckedAnalytics(props);
    return {
      email: "",
      name: "",
      lastName: "",
      country,
      partnerUpdateOptIn: prechecked,
      password: "",
      twilightUpdateOptIn: prechecked,
      reCaptchaToken: "",
    };
  },
  validationSchema: authSignupSchema,
})(SignupFormTemplate);

export const SchoolSignupForm = withFormik<SignupFormProps, IAuthSignupSchool>({
  displayName: "SchoolSignupForm",
  handleSubmit: (
    values: IAuthSignupSchool,
    formikBag: FormikBag<SignupFormProps, IAuthSignupSchool>
  ) => {
    formikBag.props
      .authSignup({
        ...values,
        email: values.email.toLocaleLowerCase(),
      } as IAuthSignupSchool)
      .catch((e) => {
        // fill rejected fields
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
        if (reCaptchaReference.current) {
          reCaptchaReference.current.reset();
        }
        formikBag.setSubmitting(false);
      })
      .finally(() => {
        values.reCaptchaToken = "";
      });
  },
  mapPropsToValues: (props: SignupFormProps): IAuthSignupSchool => {
    let { prechecked, country } = precheckedAnalytics(props);
    return {
      name: "",
      lastName: "",
      email: "",
      country,
      password: "",
      partnerUpdateOptIn: prechecked,
      twilightUpdateOptIn: prechecked,
      reCaptchaToken: "",
      schoolName: "",
      schoolUrl: "",
      schoolType: "",
      schoolCity: "",
      schoolState: "",
      schoolCountry: country,
    };
  },
  validationSchema: authSignupSchoolSchema,
})(SignupFormTemplate);
