import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApplicationState } from "../../store";
import { Promotion } from "../../store/promotion";

import withConfirmAnimation from "../higherOrderComponents/layout/withConfirmAnimation";
import withEmail from "../higherOrderComponents/parameters/withEmail";
import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";
import Download from "../download/Download";
import FlowIndicator from "../flow/FlowIndicator";

import "./Payment.scss";
import { isMobile } from "react-device-detect";

interface Props {
  email: string;
  backgroundColor: string;
  componentBackgroundColor: string;
  emailBackgroundColor: string;
  flowIndicatorStep: number;
  loadingComplete: () => void;
}

const PaymentConfirmation = ({
  email,
  backgroundColor,
  componentBackgroundColor,
  flowIndicatorStep,
  loadingComplete,
}: Props) => {
  const auth = useSelector((state: ApplicationState) => state.auth);
  const promotionState = useSelector(
    (state: ApplicationState) => state.promotion
  );
  const profile = useSelector((state: ApplicationState) => state.profile);

  const [promotion, setPromotion] = useState<Promotion>();
  useEffect(() => {
    if (promotionState && promotionState.data) {
      if (promotionState.selectedAlternativePromotion) {
        setPromotion(promotionState.data.alternativePromotion);
      } else {
        setPromotion(promotionState.data);
      }
    }
  }, [promotionState]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (auth.isLoggedIn) {
      if (
        profile &&
        profile.UserData &&
        profile.SubscriptionData &&
        promotion
      ) {
        setIsLoading(false);
        setTimeout(loadingComplete, 300);
      }
    } else if (auth.user && auth.user.sub && promotion) {
      setIsLoading(false);
      setTimeout(loadingComplete, 300);
    }
  }, [auth, promotion, profile, loadingComplete]);

  const [topTitle, setTopTitle] = useState("");
  useEffect(() => {
    if (promotion && promotion.labels) {
      if (!profile.SubscriptionData || !profile.SubscriptionData.trialActive) {
        setTopTitle(promotion.labels.pageConfirmationStatusNoTrial || "");
      } else {
        setTopTitle(promotion.labels.pageConfirmationStatus);
      }
    }
  }, [promotion, profile]);

  if (isLoading) {
    return (
      <div
        className="app-body center payment-page payment-confirmation-page"
        style={{ backgroundColor: backgroundColor }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div
      className="app-body center payment-page payment-confirmation-page"
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className="component-box with-header"
        style={
          !isMobile
            ? { backgroundColor: componentBackgroundColor }
            : { backgroundColor: "transparent" }
        }
      >
        <div className="component-box-header">
          <h1 className="font-heading">Thank you</h1>
          <FlowIndicator
            stepName={"Download"}
            step={
              promotion?.promotionSlug === "sleepies"
                ? flowIndicatorStep + 1
                : flowIndicatorStep
            }
          />
        </div>
        <h1 className="text-center top-title">{topTitle}</h1>
        <div
          className="mobile-section"
          style={
            isMobile
              ? { backgroundColor: componentBackgroundColor }
              : { backgroundColor: "transparent" }
          }
        >
          <p className="copy">
            Download the app and login to start enjoying calmer day times and
            easier bedtimes.
          </p>
          <Download />
        </div>

        <p className="text-center m-2 mb-1">
          We have emailed you a confirmation email at:
        </p>
        <p className="email-text">{email}</p>
      </div>
      <div className="component-after" />
    </div>
  );
};

export default withConfirmAnimation(withEmail(PaymentConfirmation));
