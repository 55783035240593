import Api from "clients/helpers/api";

import { ProfileState } from "../types";
import { ApiError, ValidationError } from "shared/helpers/errors";
import { getTimeZone } from "../helpers";
import { ClientSubscriptionData } from "../../../../shared/store/user/types";

/**
 * API: client_api/braintree.types.js
 */
export interface IBraintreeCreateCustomer {
  paymentMethodNonce: string;
  userSub: string;
  withTrial: boolean;
  promotionSlug: string;
  countryCode?: string;
  codeUsed?: string;
  referrer?: string;
  referralUsed?: string;
}

/**
 * API: client_api/braintree.types.js
 */
export interface IBraintreeUpdatePayment {
  paymentMethodNonce: string;
  withTrial?: boolean;
  promotionSlug?: string;
  countryCode?: string;
  codeUsed?: string;
  referrer?: string;
  referralUsed?: string;
}

export interface IBraintreeUpdateCancel {
  subscriptionData: ClientSubscriptionData;
}
/**
 * Use when the user is not logged in
 */
export async function braintreeCreateCustomer(
  data: IBraintreeCreateCustomer
): Promise<ProfileState> {
  return Api.post("/braintree/create-customer", {
    ...data,
    timeZone: getTimeZone(),
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}

/**
 * Create subscription
 */
export async function braintreeSubscribe(
  data: IBraintreeUpdatePayment
): Promise<ProfileState> {
  return Api.put("/braintree/update-subscription", {
    ...data,
    timeZone: getTimeZone(),
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}

/**
 * Update payment method
 */
export async function braintreeUpdatePayment(
  data: IBraintreeUpdatePayment
): Promise<ProfileState> {
  return Api.put("/braintree/update-subscription", {
    ...data,
    timeZone: getTimeZone(),
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}

/**
 * Cancel the subscription
 */
export async function braintreeCancelSubscription(): Promise<IBraintreeUpdateCancel> {
  return Api.put("/braintree/update-subscription/cancel").catch((e) => {
    throw handleBraintreeException(e);
  });
}

/**
 * We dont want the user to know what's going on
 */
export class BraintreeGeneralError extends Error {
  constructor() {
    super("Error processing subscription data.");
  }
}

/**
 * Critical server errors are handled,
 *  we catch API errors here and
 */
export function handleBraintreeException(error: Error) {
  if (error instanceof ValidationError) {
    // this cant really happen since the user is not entering any data at all,
    //  so let's report this error
  } else if (error instanceof ApiError) {
    // a known error happened, lets handle it nicely
    return error;
  }
  return new BraintreeGeneralError();
}
