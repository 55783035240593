import { ProfileState } from "../types";
import Api from "clients/helpers/api";

import { ProfileUpdateParameters } from "shared/store/user/types";

/**
 * Get the current user from the api
 */
export async function getCurrentUser(): Promise<ProfileState> {
  const { UserData, SubscriptionData } = await Api.get("/user/current-user");

  return {
    UserData: UserData ? UserData : null,
    SubscriptionData: SubscriptionData ? SubscriptionData : null,
  } as ProfileState;
}

export async function putCurrentUser(
  params: ProfileUpdateParameters
): Promise<ProfileState> {
  return (await Api.put("/user/current-user", {
    ...params,
  })) as ProfileState;
}

export default {
  getCurrentUser,
  putCurrentUser,
};
