import mixpanel from "mixpanel-browser";
import { AnalyticsEventParameters, EventName } from "./types";

class Mixpanel {
  private static instance: Mixpanel;

  private static init(debug: boolean) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY || "", {
      debug,
      ignore_dnt: true,
    });
  }

  public static getInstance() {
    if (!Mixpanel.instance) {
      Mixpanel.instance = new Mixpanel(
        process.env.REACT_APP_MIXPANEL_DEBUG === "true" || false
      );
    }
    return Mixpanel.instance;
  }

  private constructor(debug = false) {
    Mixpanel.init(debug);
  }

  private email = "";

  private schoolName?: string;

  public identifyUser(email: string) {
    this.email = email;
    mixpanel.identify(this.getDistinctId());
    mixpanel.people.set({ $email: this.email });
  }

  public resetUser() {
    this.email = "";
    this.schoolName = undefined;
    mixpanel.reset();
  }

  public getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  public identifySchool(email: string, schoolName: string) {
    this.email = email;
    this.schoolName = schoolName;
    mixpanel.identify(this.getDistinctId());
    mixpanel.people.set({ $email: this.email, schoolName: this.schoolName });
  }

  public trackEvent(eventName: EventName, params?: AnalyticsEventParameters) {
    mixpanel.track(eventName, params);
  }
}

export default Mixpanel;
