import { ApplicationState } from "../index";
import { ProfileActionsTypes as actions } from "./types";
import { AuthActionsTypes } from "shared/store/auth";

import ProfileApi from "./api/user";

import { put, select, takeLatest, call } from "redux-saga/effects";

import { toast } from "react-toastify";

import { listDevices, deleteDevice } from "./api/devices";

import {
  profileSetAction,
  ProfileDeviceDelete,
  ProfileUpdate,
  profileFetchAction,
  profileDevicesFetchAction,
} from "./actions";

import { captureBreadcrumb, captureException } from "clients/helpers/logger";

function* profileFetch() {
  yield put(profileSetAction({ isLoading: true }));
  try {
    const profileData = yield call(ProfileApi.getCurrentUser);
    yield put(profileSetAction({ isLoading: false, ...profileData }));
  } catch (e) {
    yield put(profileSetAction({ isLoading: false }));
  }
}

function* profileUpdate(action: ProfileUpdate) {
  yield put(profileSetAction({ isLoading: true }));

  try {
    const profileData = yield call(ProfileApi.putCurrentUser, action.payload);
    yield put(profileSetAction({ isLoading: false, ...profileData }));
  } catch (e) {
    captureException(e, { category: "profile-update" });
    toast.error(`Something went wrong, refresh the page and try again!`);
  }
}

function* deviceDelete(action: ProfileDeviceDelete) {
  try {
    yield deleteDevice(action.payload);
    toast.success("Device deleted");
    yield put(profileDevicesFetchAction());
  } catch (e) {
    if (!e.code || e.code !== "ResourceNotFoundException") {
      captureException(e, { category: "delete-device" });
    } else {
      captureBreadcrumb({
        category: "delete-device",
        data: {
          exception: e,
        },
      });
    }
    if (e.message) {
      toast.error(e.message);
    } else {
      toast.error(
        "Something went wrong, please try again deleting your device."
      );
    }
  }
}

function* devicesFetch() {
  try {
    const profileState = yield call(listDevices);
    yield put(profileSetAction({ DeviceList: profileState.DeviceList }));
  } catch (e) {
    captureException(e, { category: "fetch-device" });
    yield put(profileSetAction({ DeviceList: [] }));
    toast.error("Could not load devices.");
  }
}

export const getAuthFromStore = (state: ApplicationState) => state.auth;

export const getProfileFromStore = (state: ApplicationState) => state.profile;

/**
 * Run when auth updates
 * Fetch Profile if we can
 */
function* onAuthUpdate() {
  const { user, isLoggedIn } = yield select(getAuthFromStore);
  if (user && isLoggedIn) {
    const profile = yield select(getProfileFromStore);
    if (
      !profile.profileData ||
      (profile.userSub && profile.userSub !== user.sub)
    ) {
      yield put(profileFetchAction());
    }
  } else {
    yield put(
      profileSetAction({
        DeviceList: undefined,
        SubscriptionData: undefined,
        UserData: undefined,
        userSub: undefined,
      })
    );
  }
}

export function* profileSagas() {
  yield takeLatest(actions.PROFILE_FETCH, profileFetch);
  yield takeLatest(actions.PROFILE_UPDATE, profileUpdate);
  yield takeLatest(actions.PROFILE_DEVICES_FETCH, devicesFetch);
  yield takeLatest(actions.PROFILE_DEVICE_DELETE, deviceDelete);
  // update profile when auth gets updated
  yield takeLatest(AuthActionsTypes.AUTH_SET, onAuthUpdate);
}
