import * as Yup from "yup";

import {
  ENTER_VALID_EMAIL,
  ENTER_VALID_PASSWORD,
  NAME_CANNOT_BE_BLANK,
  NAME_MUST_BE_LONGER_THAN,
  NAME_MUST_BE_SHORTER_THAN,
  SCHOOL_CITY_CANNOT_BE_BLANK,
  SCHOOL_NAME_CANNOT_BE_BLANK,
  SCHOOL_NAME_MUST_BE_LONGER_THAN,
  SCHOOL_TYPE_CANNOT_BE_EMPTY,
  USERNAME_ALREADY_EXISTS,
} from "shared/helpers/messages";
import {
  CognitoUserAttribute,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import Auth from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import { getTimeZone } from "../../../clients/store/profile/helpers";
import { URLRegex } from "../../helpers/schema";
import Mixpanel from "../../../clients/helpers/analytics/mixpanel";
import Adjust from "../../../clients/helpers/analytics/adjust";

export interface IAuthSignup {
  name: string;
  lastName: string;
  email: string;
  country: string;
  password: string;
  partnerUpdateOptIn: boolean;
  analyticsOptIn?: boolean;
  twilightUpdateOptIn: boolean;
  reCaptchaToken: string;
  [key: string]: string | boolean | undefined;
}
export interface IAuthSignupSchool extends IAuthSignup {
  schoolName: string;
  schoolUrl: string;
  schoolType: "" | "Private" | "Public" | "Charter";
  schoolCity: string;
  schoolState?: string;
  schoolCountry: string;
}

export const authSignupSchema = Yup.object().shape({
  analyticsOptIn: Yup.boolean().notRequired(),
  email: Yup.string()
    .trim()
    .lowercase()
    .matches(/^[^\s]+$/, "Email field cannot contain spaces.")
    .matches(/^[^A-Z]+$/, "Email must be lowercase.")
    .email(ENTER_VALID_EMAIL)
    .required(ENTER_VALID_EMAIL),
  name: Yup.string()
    .trim()
    .min(2, NAME_MUST_BE_LONGER_THAN)
    .max(25, NAME_MUST_BE_SHORTER_THAN)
    .required(NAME_CANNOT_BE_BLANK),
  lastName: Yup.string()
    .trim()
    .min(2, NAME_MUST_BE_LONGER_THAN)
    .max(25, NAME_MUST_BE_SHORTER_THAN)
    .required(NAME_CANNOT_BE_BLANK),
  partnerUpdateOptIn: Yup.boolean(),
  password: Yup.string()
    .matches(/^[^\s]+$/, "Password field cannot contain spaces.")
    .min(8, ENTER_VALID_PASSWORD)
    .matches(/[0-9]/, ENTER_VALID_PASSWORD)
    .required(ENTER_VALID_PASSWORD),
  twilightUpdateOptIn: Yup.boolean(),
  reCaptchaToken: Yup.string()
    .min(1, "Please verify you are not a robot")
    .required("Please verify you are not a robot"),
});

export const authSignupSchoolSchema = authSignupSchema.shape({
  schoolName: Yup.string()
    .min(3, SCHOOL_NAME_MUST_BE_LONGER_THAN)
    .required(SCHOOL_NAME_CANNOT_BE_BLANK),
  schoolUrl: Yup.string()
    .matches(URLRegex, "Website must be a valid url.")
    .required("Website must be a valid url."),
  schoolType: Yup.string()
    .oneOf(["public", "private", "charter"])
    .required(SCHOOL_TYPE_CANNOT_BE_EMPTY),
  schoolCity: Yup.string().required(SCHOOL_CITY_CANNOT_BE_BLANK),
  schoolState: Yup.string().notRequired(),
  schoolCountry: Yup.string().required(),
});

export class UsernameExists extends Error {
  constructor() {
    super(USERNAME_ALREADY_EXISTS);
  }
}

export const tempUserSchema = Yup.object().shape({
  username: Yup.string().trim().min(3).required(),
  name: Yup.string().trim().min(3).required(),
  lastName: Yup.string().trim().min(3).required(),
  email: Yup.string().email().required(),
  sub: Yup.string().trim().min(3).required(),
});

function addSchoolAccountValidationData(
  data: any,
  signupData: IAuthSignupSchool
) {
  data.validationData.push(
    ...[
      new CognitoUserAttribute({
        Name: "schoolName",
        Value: signupData.schoolName,
      }),
      new CognitoUserAttribute({
        Name: "schoolUrl",
        Value: signupData.schoolUrl,
      }),
      new CognitoUserAttribute({
        Name: "schoolType",
        Value: signupData.schoolType,
      }),
      new CognitoUserAttribute({
        Name: "schoolCity",
        Value: signupData.schoolCity,
      }),
      new CognitoUserAttribute({
        Name: "schoolCountry",
        Value: signupData.schoolCountry,
      }),
    ]
  );
  if (signupData.schoolState) {
    data.validationData.push(
      new CognitoUserAttribute({
        Name: "schoolState",
        Value: signupData.schoolState,
      })
    );
  }
}

export async function signupCognitoUser(
  signupData: IAuthSignup | IAuthSignupSchool,
  referralCode?: string
): Promise<ISignUpResult> {
  const validationData = [
    new CognitoUserAttribute({
      Name: "twilightUpdateOptIn",
      Value: "" + signupData.twilightUpdateOptIn,
    }),
    new CognitoUserAttribute({
      Name: "partnerUpdateOptIn",
      Value: "" + signupData.partnerUpdateOptIn,
    }),
    new CognitoUserAttribute({
      Name: "platform",
      Value: "web",
    }),
    new CognitoUserAttribute({
      Name: "reCaptchaToken",
      Value: "" + signupData.reCaptchaToken,
    }),
    new CognitoUserAttribute({
      Name: "country",
      Value: signupData.country,
    }),
    new CognitoUserAttribute({
      Name: "mixpanelDistinctId",
      Value: Mixpanel.getInstance().getDistinctId(),
    }),
  ];
  if (Adjust.uuid) {
    validationData.push(
      new CognitoUserAttribute({
        Name: "adjustDistinctId",
        Value: Adjust.uuid,
      })
    );
  }
  if (signupData.country === "US") {
    validationData.push(
      new CognitoUserAttribute({
        Name: "optOutOfSale",
        Value: "" + false,
      })
    );
  }
  if (referralCode) {
    validationData.push(
      new CognitoUserAttribute({
        Name: "referralCodeUsed",
        Value: referralCode,
      })
    );
  }
  const data = {
    username: signupData.email.replace("@", "-at-"),
    password: signupData.password,
    attributes: {
      email: signupData.email,
      name: signupData.name,
      family_name: signupData.lastName,
      // we have multiple timezones and countries but just one locale
      locale: "en_GB",
    },
    validationData,
  };

  const timeZone = getTimeZone();
  if (timeZone) {
    data.validationData.push(
      new CognitoUserAttribute({
        Name: "timeZone",
        Value: timeZone,
      })
    );
  }
  if ((signupData as IAuthSignupSchool).schoolName !== undefined) {
    addSchoolAccountValidationData(data, signupData as IAuthSignupSchool);
  }

  try {
    return await Auth.signUp(data);
    // the user is signed up, but we dont have the actual cognito user since
    //  the user is not confirmed yet.
  } catch (e) {
    switch (e.code) {
      case "InvalidParameterException":
        // we should be preventing this client side
        break;
      case "UsernameExistsException":
      case "AliasExistsException":
        throw new UsernameExists();
      case "InvalidPasswordException":
        // should not be in use since we do check client side
        break;
    }
    Sentry.captureException(e);
    throw e;
  }
}
