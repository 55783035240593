import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  Messaging,
  MessagePayload,
} from "firebase/messaging";
import Iterable from "../analytics/iterable";

class Firebase {
  private static instance: Firebase;

  private constructor() {
    this.init();
    this.initMessaging();
  }

  private app: FirebaseApp | undefined;

  private messaging: Messaging | undefined;

  private token = "";

  private init() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${process.env.REACT_APP_PROJECT_ID}.firebaseio.com`,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
    this.app = initializeApp(firebaseConfig);
  }

  private initMessaging() {
    if (!this.app) {
      this.init();
    }
    this.messaging = getMessaging(this.app);
  }

  public static getInstance() {
    if (!Firebase.instance) {
      Firebase.instance = new Firebase();
    }
    return Firebase.instance;
  }

  public getMessagingToken(email?: string) {
    if (this.messaging) {
      getToken(this.messaging, {
        vapidKey: process.env.REACT_APP_VAPID_ID,
      })
        .then((currentToken) => {
          if (currentToken) {
            this.token = currentToken;
            const iterable = Iterable.getInstance();
            iterable.pushBrowserToken(currentToken, email);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((e) => {
          console.log("An error occurred while retrieving token. ", e);
          return false;
        });
    }
  }

  public onMessageListener() {
    return new Promise<MessagePayload>((resolve) => {
      if (this.messaging) {
        onMessage(this.messaging, (payload) => {
          resolve(payload);
        });
      }
    });
  }
}

export default Firebase;
