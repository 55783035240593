import React from "react";
import "./NavigationButtonMobile.scss";

interface Props {
  onMenuToggle: () => void;
  menuOpen: boolean;
}

const NavigationButtonMobile = ({ onMenuToggle, menuOpen }: Props) => {
  return (
    <button
      className={"navigation-menu-button" + (menuOpen ? " open" : "")}
      onClick={onMenuToggle}
    >
      <div>
        <span />
        <span />
        <span />
      </div>
    </button>
  );
};

export default NavigationButtonMobile;
