import React from "react";
import { NavLink } from "react-router-dom";
import { Path } from "../../../store/path/constants";

const LoginLinks = () => {
  return (
    <div className="component-after">
      <p className="small text-center pr-2 pl-2">
        <NavLink to={Path.RESET_PASSWORD}>Forgot password?</NavLink>
      </p>
    </div>
  );
};

export default LoginLinks;
