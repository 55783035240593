import React from "react";
import { CategoryData } from "../../../../store/content";
import DropDownMenu from "shared/components/controls/dropDown/DropDownMenu";

import "./ContentFilter.scss";

interface Props {
  selTagData: Set<string>;
  themeData: Set<string>;
  categoryData: CategoryData[];
  onFilterChange: (filterId: string, filterType?: string) => void;
  onCategoryChange: (category: string) => void;
  initialSelectedCategory?: string;
}

const ContentFilter = ({
  selTagData,
  themeData,
  categoryData,
  onFilterChange,
  onCategoryChange,
  initialSelectedCategory,
}: Props) => {
  const categoryOptions = categoryData.map((category) => ({
    label: category.name,
    id: category.id,
  }));
  const selTagOptions = Array.from(selTagData).map((selTag) => ({
    label: selTag,
    id: selTag,
  }));
  const themeOptions = Array.from(themeData).map((theme) => ({
    label: theme,
    id: theme,
  }));

  return (
    <div className="content-filter">
      <DropDownMenu
        noSelectionTitle="Choose SEL category"
        options={selTagOptions}
        onSelect={onFilterChange}
        selectionType="sel"
        allowClear
      />
      <DropDownMenu
        noSelectionTitle="Choose theme"
        options={themeOptions}
        onSelect={onFilterChange}
        selectionType="theme"
        allowClear
      />
      <DropDownMenu
        noSelectionTitle="Choose content type"
        options={categoryOptions}
        onSelect={onCategoryChange}
        preSelectedOption={initialSelectedCategory}
        selectionType="category"
      />
    </div>
  );
};

export default ContentFilter;
