import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { authLogoutAction } from "../../../../shared/store/auth";
import { navigationMenuItems } from "../constants";
import { searchLink } from "../../footer/data/links";
import { events } from "../../../helpers/logger";

import "./NavigationMenu.scss";

import searchImage from "../images/search.svg";
import profileImage from "../images/profile.svg";
import logoutImage from "../images/log-out.svg";

const NavigationMenu = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: ApplicationState) => state.auth);
  const isSchoolSubscription = useSelector(
    (state: ApplicationState) =>
      state.profile.SubscriptionData?.isSchoolSubscription
  );

  const [canLogout, setCanLogout] = useState(false);
  useEffect(() => {
    setCanLogout(!!auth.user);
  }, [auth, canLogout]);

  const callLogout = () => {
    dispatch(authLogoutAction());
    events.logout.success();
  };

  return (
    <div className="navigation-menu">
      <ul className="navigation-main-menu">
        {navigationMenuItems.map((item) => (
          <li id={item.id} key={item.id} className="navigation-main-menu-item">
            <a href={item.href}>{item.label}</a>
          </li>
        ))}
        {!auth.isLoggedIn && (
          <li id="login" key="login" className="navigation-main-menu-item">
            <NavLink to="/login">Login</NavLink>
          </li>
        )}
        {isSchoolSubscription && (
          <li
            id="web-player"
            key="web-player"
            className="navigation-main-menu-item"
          >
            <NavLink to="/schools/menu">Web player</NavLink>
          </li>
        )}
      </ul>
      <div className="navigation-action-menu">
        <a
          className="navigation-action-menu-item tooltip-parent"
          href={searchLink.href}
        >
          <img src={searchImage} alt="Search" />
          <span className="tooltip">{searchLink.label}</span>
        </a>
        {auth.isLoggedIn && (
          <React.Fragment>
            <NavLink
              className="navigation-action-menu-item tooltip-parent"
              to="/profile"
            >
              <img src={profileImage} alt="Profile" />
              <span className="tooltip">Profile</span>
            </NavLink>
            <div className="tooltip-parent">
              <img
                className="navigation-action-menu-item "
                src={logoutImage}
                alt="Logout"
                onClick={callLogout}
              />
              <span className="tooltip">Logout</span>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default NavigationMenu;
