import React, {
  ComponentType,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "clients/store";
import { ApprovalStatus } from "../../../../shared/store/user/types";
import { toast } from "react-toastify";

enum Errors {
  pending = "Your account is currently pending approval. Please wait to be notified via email or contact customer support at schools@moshikids.com",
  expired = "Your school subscription has now expired. Please contact us at schools@moshikids.com to renew.",
  declined = "You do not have access to this content. To start a Moshi for Schools subscription, please sign up via https://www.moshikids.com/moshi-for-schools",
  notLoggedIn = "You need to be signed into a Moshi for Schools account to access this content",
  accessDenied = "You do not have access to this content. To start a Moshi for Schools subscription, please sign up via https://www.moshikids.com/moshi-for-schools",
}
function withSchoolOnlyAccess<T>(
  WrappedComponent: ComponentType<T>
): FunctionComponent<any> {
  return (props: T) => {
    const history = useHistory();
    const auth = useSelector((state: ApplicationState) => state.auth);
    const profile = useSelector((state: ApplicationState) => state.profile);

    let [errorMessage, setErrorMessage] = useState<string>();
    let [redirectPath, setRedirectPath] = useState<string>();
    useEffect(() => {
      if (profile.UserData?.schoolDetails) {
        switch (profile.UserData.schoolDetails.approvalStatus) {
          case ApprovalStatus.expired:
            setErrorMessage(Errors.expired);
            break;
          case ApprovalStatus.declined:
            setErrorMessage(Errors.declined);
            break;
        }
        setRedirectPath("/profile");
      } else if (!profile.UserData && !auth.isLoggedIn && auth.isLoaded) {
        setErrorMessage(Errors.notLoggedIn);
        setRedirectPath("/login");
      } else if (auth.isLoaded && !auth.isLoggedIn && !profile.isLoading) {
        setErrorMessage(Errors.accessDenied);
        setRedirectPath("/profile");
      }
    }, [profile, auth]);

    useEffect(() => {
      if (errorMessage) {
        toast.error(errorMessage);
        history.replace(redirectPath);
      }
    }, [errorMessage, redirectPath, history]);

    return <WrappedComponent {...{ ...props }} />;
  };
}

export default withSchoolOnlyAccess;
