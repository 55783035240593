import { Reducer } from "redux";
import { ContentActions } from "./actions";
import { ContentState, ContentActionTypes } from "./types";

const reducer: Reducer<ContentState, ContentActions> = (state = {}, action) => {
  if (action.type === ContentActionTypes.CONTENT_SET) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }
};

export { reducer as contentReducer };
