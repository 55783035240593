import { ClientSubscriptionData } from "shared/store/user/types";

export interface Promotion {
  promotionSlug: string;
  merchantAccountSlug: string;
  groups: string[];
  countryCode: string;
  currencyIsoCode: string;
  basePrice: string;
  discountPrice: string;
  billingFrequency: number;
  withTrial: boolean;
  labels: Labels;
  readonly alternativePromotion?: Promotion;
}

export interface Labels {
  [key: string]: string;
}

export interface LabelizedSubscription {
  showUpgrade: boolean;
  showCancel: boolean;
  startTimeDate: string | null;
  renewalTimeDate: string | null;
  renewalLabel: string;
  renewalDetails: string;
  storeLabel: any;
  platform: string;
  storeLink: any;
  renewalTimeDateTitle: string | null;
  startTimeDateTitle: string | null;
  subscriptionStatusDetails: string;
  codeDetails: any;
  paymentDetails: string;
  showUpdate: boolean;
  promotionDetails: string;
}

export interface PartnerInfo {
  partnerDisplayName: string;
  partnerLogoImagePath: string;
  promotionSlug: string;
  showRedeemScreen: boolean;
  isRefererHeaderValid: boolean;
  promoCode: string;
  isPromoCodeValid: boolean;
}

export interface ReferralInfo {
  isUsable: boolean;
  subscriptionSku: string;
  referralCode: string;
  referralCodeOwnerName: string;
  userHadTrialBefore: boolean;
}

export interface ProductInfo {
  readonly isValid: boolean;
  readonly productRedeemType: string;
  readonly productRedeemPromotionSlug: string;
  readonly userHadTrialBefore: boolean;
}

export interface PromotionState {
  readonly error?: Error;
  readonly country?: string; // QA country override
  readonly data?: Promotion;
  readonly partner?: PartnerInfo;
  readonly referral?: ReferralInfo;
  readonly promoCode?: string;
  readonly selectedAlternativePromotion?: boolean;
}

export enum PromotionActionsTypes {
  PROMOTION_SET = "@@promotion/PROMOTION_SET",
  PROMOTION_SELECT = "@@promotion/PROMOTION_SELECT",
}

export interface PromoCodeRedeemResponse {
  readonly redeemType: string;
  readonly promoCodeSubscriptionData?: ClientSubscriptionData;
  readonly referralCodeData?: ReferralInfo;
  readonly productCodeData?: ProductInfo;
  readonly partnerCodeData?: PartnerInfo;
}

export interface CheckSchoolCodeResponse {
  isPromoCodeValid: boolean;
  redeemType: string;
}
