import braintree, { BraintreeError } from "braintree-web";

import { braintreeGetClientToken } from "../../../store/payment/api/braintree";
import { PaymentForm, PaymentFormProps, PaymentFormState } from "./PaymentForm";
import { events } from "../../../helpers/logger";
import {
  ThreeDSecureErrors,
  rejectAtLookup,
  rejectAfterLookup,
} from "../../../store/payment";

interface ThreeDSecureInfo {
  amount: string;
  email?: string;
  merchantAccount: string;
}

interface PaymentFormSecureProps extends PaymentFormProps {
  threeDSecureInfo: ThreeDSecureInfo;
}

interface PaymentFormSecureState extends PaymentFormState {
  amount: string;
}

export class PaymentFormSecure extends PaymentForm<
  PaymentFormSecureProps,
  PaymentFormSecureState
> {
  protected threeDsecure: braintree.ThreeDSecure | null = null;

  private readonly amount: string;
  private readonly email?: string;
  private readonly merchantAccountSlug: string;

  constructor(props: PaymentFormSecureProps) {
    super(props);
    this.amount = props.threeDSecureInfo.amount;
    this.email = props.threeDSecureInfo.email;
    this.merchantAccountSlug = props.threeDSecureInfo.merchantAccount;
    this.state = {
      ...this.state,
      amount: this.props.threeDSecureInfo.amount,
    };
  }

  public updateAmount(newAmount: string) {
    this.setState({ amount: newAmount });
    this.forceUpdate();
  }

  public async tokenize() {
    if (!this.hostedFields) {
      return Promise.resolve();
    }

    events.authorization.submitted();

    const { hostedFields, fields, onToken, onLookupComplete } = this;
    return new Promise((resolve, reject) => {
      hostedFields.tokenize(
        { cardholderName: fields.cardholderName.inputNode!.current!.value },
        (tokenizeErr, tokenizationPayload) => {
          if (tokenizeErr) {
            reject(tokenizeErr);
          } else if (this.threeDsecure) {
            const threeDSecureParameters = {
              nonce: tokenizationPayload.nonce,
              bin: tokenizationPayload.details.bin,
              amount: this.state.amount,
              email: this.email,
              onLookupComplete,
            };
            this.threeDsecure.verifyCard(
              // @ts-ignore
              threeDSecureParameters,
              (err: BraintreeError, verifyPayload: any) => {
                if (err) {
                  reject(err);
                  return;
                }
                if (verifyPayload.liabilityShifted) {
                  // Liability has shifted
                  events.authorization.success(
                    "Card authorized",
                    "card 3D secure"
                  );
                  onToken(verifyPayload);
                  resolve();
                } else {
                  // Liability has not shifted and will not shift
                  const statusCode = verifyPayload.threeDSecureInfo.status;
                  if (rejectAfterLookup(statusCode)) {
                    const threeDSecureFailedError = {
                      code: statusCode,
                      message: ThreeDSecureErrors[statusCode](),
                    };
                    reject(threeDSecureFailedError);
                  } else {
                    events.authorization.success(
                      "Card authorized with no liability shift",
                      "card 3D secure"
                    );
                    onToken(verifyPayload);
                    resolve();
                  }
                }
              }
            );
          }
        }
      );
    });
  }

  protected async onLookupComplete(lookupPayload: any, next: () => {}) {
    if (lookupPayload.threeDSecureInfo.liabilityShifted) {
      // Liability has shifted
      next();
    } else {
      const statusCode = lookupPayload.threeDSecureInfo.status;
      if (rejectAtLookup(statusCode)) {
        this.threeDsecure!.cancelVerifyCard((err, _) => {
          if (err) {
            throw err;
          }
          // pass error to verify callback
          next();
        });
      } else {
        // pass through to verify callback to handle
        next();
      }
    }
  }

  protected async setAuthorization(authorization: string) {
    const result = await braintreeGetClientToken(this.merchantAccountSlug);
    await super.setAuthorization(result.clientToken);
    if (this.braintreeClient) {
      this.threeDsecure = await braintree.threeDSecure.create({
        client: this.braintreeClient,
        version: 2,
      });
    }
  }
}

export default PaymentFormSecure;
