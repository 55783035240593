import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { labelize } from "shared/store/user/helpers";

import { ApplicationState } from "../../../store";
import {
  SubscriptionPlatform,
  ClientSubscriptionData,
} from "shared/store/user/types";
import ModalConfirm from "shared/components/modal/ModalConfirm";
import { profileFetchAction } from "../../../store/profile";
import { toast } from "react-toastify";
import { braintreeCancelSubscription } from "../../../store/profile/api/braintree";
import { Path } from "../../../store/path/constants";
import SubscriptionSectionShared from "shared/components/users/sections/SubscriptionSection";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import { LabelizedSubscription } from "../../../store/promotion";

const SubscriptionSection = ({
  redirect,
}: {
  redirect: (path: string) => void;
}) => {
  const webPrefix = process.env.REACT_APP_WEB_URL || "";

  const dispatch = useDispatch();

  const profile = useSelector((state: ApplicationState) => state.profile);

  const [subscriptionData, setSubscriptionData] =
    useState<ClientSubscriptionData>();
  useEffect(() => {
    if (profile.SubscriptionData) {
      setSubscriptionData(profile.SubscriptionData);
    }
  }, [profile]);

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [labels, setLabels] = useState<LabelizedSubscription>();
  useEffect(() => {
    if (subscriptionData) {
      setLabels(labelize(subscriptionData, profile.UserData?.schoolDetails));
      setIsLoading(false);
    }
  }, [profile, subscriptionData, isLoading]);

  const upgradeBraintreeSubscription = () => {
    redirect(Path.PAYMENT + "?promo=standard");
  };

  const cancelBraintreeSubscription = () => {
    if (
      !subscriptionData ||
      subscriptionData.platform !== SubscriptionPlatform.Braintree
    ) {
      throw new Error("Not a Braintree subscription");
    }
    setShowModal(true);
  };

  const confirmCancelBraintreeSubscription = async () => {
    setShowModal(false);
    try {
      setIsLoading(true);
      const response = await braintreeCancelSubscription();
      if (response.subscriptionData) {
        toast.success("Subscription renewal has been cancelled");
      }
      dispatch(profileFetchAction());
    } catch (e) {
      if (e.message) {
        toast.error(e.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  };

  const updatePaymentDetails = () => {
    redirect(Path.PAYMENT_UPDATE);
  };

  const closeModal = () => setShowModal(false);

  if (
    !subscriptionData ||
    subscriptionData.subscriptionType === "neverSubscribed"
  ) {
    return (
      <div className="component-box">
        <div className="section subscription-section ">
          <div className="content subscription">
            <h1>Subscription</h1>
            {labels && (
              <p
                className="font-bold small"
                dangerouslySetInnerHTML={{
                  __html: labels.subscriptionStatusDetails,
                }}
              />
            )}
            {labels?.showUpgrade && (
              <div className="actions with-loading-block">
                <button
                  className="btn action filled wide"
                  onClick={upgradeBraintreeSubscription}
                  disabled={!upgradeBraintreeSubscription}
                >
                  Upgrade
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="component-box">
        <div className={`section subscription-section with-loading-block`}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div className="content subscription">
              <SubscriptionSectionShared
                subscriptionData={subscriptionData}
                labels={labels}
              />
              <div className="actions mt-3 mb-3">
                {labels?.showCancel && (
                  <button
                    type="button"
                    onClick={cancelBraintreeSubscription}
                    className="btn secondary filled wide"
                    disabled={!cancelBraintreeSubscription}
                  >
                    Cancel subscription
                  </button>
                )}
                {updatePaymentDetails && labels?.showUpdate && (
                  <button
                    type="button"
                    onClick={updatePaymentDetails}
                    className="btn secondary filled wide"
                  >
                    Update payment
                  </button>
                )}
                {labels?.storeLink && (
                  <a
                    className="btn secondary filled wide"
                    href={labels.storeLink}
                  >
                    {labels.storeLabel}
                  </a>
                )}
                {labels?.showUpgrade && (
                  <button
                    type="button"
                    onClick={upgradeBraintreeSubscription}
                    className="btn secondary filled wide"
                    disabled={!upgradeBraintreeSubscription}
                  >
                    Upgrade subscription
                  </button>
                )}
                {subscriptionData.isSchoolSubscription && (
                  <a
                    className="btn secondary filled wide"
                    href={webPrefix + Path.CONTACT}
                  >
                    Contact us
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalConfirm
        question={
          showModal
            ? "Are you sure you want to cancel your subscription?"
            : null
        }
        confirmText="Yes"
        loading={isLoading}
        close={closeModal}
        onConfirm={confirmCancelBraintreeSubscription}
      />
    </React.Fragment>
  );
};

export default SubscriptionSection;
