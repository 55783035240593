import React from "react";
import "./HeaderLogo.scss";

import logo from "../images/logo.svg";
import darkLogo from "../images/logo-dark.svg";
interface Props {
  menuOpen: boolean;
}

const HeaderLogo = ({ menuOpen }: Props) => {
  return (
    <a
      className="header-logo"
      href={process.env.REACT_APP_APP_URL}
      title="Moshi"
    >
      <svg viewBox="0 0 135 44">
        <image href={menuOpen ? darkLogo : logo} />
      </svg>
    </a>
  );
};

export default HeaderLogo;
