import React from "react";

import favoriteActiveIcon from "../../images/Icon_Active_Heart@1_5x.svg";

const StoryTitleCell = ({
  tileUrl,
  name,
  isFavorite,
}: {
  tileUrl: string;
  name: string;
  isFavorite: boolean;
}) => {
  return (
    <div className="song-title">
      <div className="thumbnail-container">
        <img
          className="thumbnail"
          src={tileUrl}
          alt={name}
          width={76}
          height={76}
        />
        {isFavorite && (
          <img className="favorite" src={favoriteActiveIcon} alt="favorite" />
        )}
      </div>

      <p>{name}</p>
    </div>
  );
};
export default StoryTitleCell;
