import React, { useState, useEffect } from "react";
import _ from "lodash";

import { CATEGORY_IDS } from "../../../../store/content/constants";
import { StoryData } from "clients/store/content";
import { TableColumn, SortColumn } from "shared/components/table/types";

import { millisecondsToMinutes } from "shared/helpers/time";

import Table from "shared/components/table/Table";
import StoryTitleCell from "./StoryTitleCell";

const MenuTable = ({
  baseUrl,
  filteredStoryData,
  favorites,
  onRowSelected,
  category,
}: {
  baseUrl: string;
  filteredStoryData: StoryData[];
  favorites: string[];
  onRowSelected: (storyId: string) => void;
  category: string;
}) => {
  const standardColumns: TableColumn[] = [
    {
      key: "spacer-start",
      path: "",
      label: " ",
      content: () => <p className="spacer" />,
    },
    {
      key: "title",
      path: "name",
      label: "Title",
      content: (story: StoryData) => (
        <StoryTitleCell
          tileUrl={constructUrl(story)}
          name={story.name}
          isFavorite={favorites.includes(story.id)}
        />
      ),
    },
    { path: "contentTypeName", label: "Type" },
    { path: "characterName", label: "Character name" },
    { path: "narrator", label: "Narrator" },
    {
      path: "duration",
      label: "Duration",
      content: (story: StoryData) => (
        <React.Fragment>{`${millisecondsToMinutes(
          story.duration
        )}m`}</React.Fragment>
      ),
    },
    {
      key: "spacer-end",
      path: "",
      label: " ",
      content: () => <p className="spacer" />,
    },
  ];

  const seriesColumns: TableColumn[] = [
    {
      key: "spacer-start",
      path: "",
      label: " ",
      content: () => <p className="spacer" />,
    },
    {
      key: "title",
      path: "name",
      label: "Title",
      content: (story: StoryData) => (
        <StoryTitleCell
          tileUrl={constructUrl(story)}
          name={story.name}
          isFavorite={false}
        />
      ),
    },
    { path: "seriesName", label: "Series" },
    { path: "episode", label: "Episode No." },
    { path: "narrator", label: "Narrator" },
    {
      path: "duration",
      label: "Duration",
      content: (story: StoryData) => (
        <p>{`${millisecondsToMinutes(story.duration)}m`}</p>
      ),
    },
    {
      key: "spacer-end",
      path: "",
      label: " ",
      content: () => <p className="spacer" />,
    },
  ];

  const [orderedStoryData, setOrderedStoryData] =
    useState<StoryData[]>(filteredStoryData);
  const [sortColumn, setSortColumn] = useState<SortColumn>({});
  useEffect(() => {
    if (sortColumn.path && sortColumn.order) {
      setOrderedStoryData(
        _.orderBy(filteredStoryData, [sortColumn.path], [sortColumn.order])
      );
    } else {
      setOrderedStoryData(filteredStoryData);
    }
  }, [sortColumn, filteredStoryData]);

  function constructUrl(story: StoryData): string {
    return baseUrl + "/" + story.id + "/" + story.tileUrl;
  }
  function handleSort(newSortColumn: SortColumn) {
    setSortColumn({ ...newSortColumn });
  }
  function handleClick(storyData: StoryData) {
    onRowSelected(storyData.id);
  }

  return (
    <Table
      columns={
        category === CATEGORY_IDS.SERIES ? seriesColumns : standardColumns
      }
      sortColumn={sortColumn}
      onSort={handleSort}
      onRowSelected={handleClick}
      data={orderedStoryData}
    />
  );
};

export default MenuTable;
