import React from "react";
import appOfTheDayImage from "./images/app-of-the-day.png";
import appRatingImage from "./images/app-rating.svg";

import "./Awards.scss";

const Awards = () => {
  return (
    <div className="awards-wrapper">
      <div className="awards">
        <img
          id="go-landing-awards"
          className="award"
          src={appOfTheDayImage}
          alt=""
        />
        <img
          id="go-landing-awards"
          className="award"
          src={appRatingImage}
          alt=""
        />
        <a
          href="https://www.kidsafeseal.com/certifiedproducts/mindcandy_moshitwilight_app.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="award"
            alt="Moshi: Sleep & Mindfulness is listed by the kidSAFE Seal Program."
            src="https://www.kidsafeseal.com/sealimage/6051160242042968773/mindcandy_moshitwilight_app_svg_darktm.svg"
          />
        </a>
      </div>
    </div>
  );
};

export default Awards;
