import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { ApplicationState } from "../../../store";
import { timeSpan } from "../../../../shared/helpers/date";

import withConfirmAnimation from "../../higherOrderComponents/layout/withConfirmAnimation";
import FlowIndicator from "../../flow/FlowIndicator";
import Download from "../../download/Download";

import "../../payment/Payment.scss";

interface Props {
  email: string;
  backgroundColor: string;
  componentBackgroundColor: string;
  emailBackgroundColor: string;
  flowIndicatorStep: number;
  loadingComplete: () => void;
}

const CodeConfirmation = ({
  email,
  backgroundColor,
  componentBackgroundColor,
  emailBackgroundColor,
  flowIndicatorStep,
  loadingComplete,
}: Props) => {
  const profile = useSelector((state: ApplicationState) => state.profile);

  const [subscriptionType, setSubscriptionType] = useState("");
  useEffect(() => {
    if (profile.SubscriptionData && profile.SubscriptionData.subscriptionType) {
      setSubscriptionType(profile.SubscriptionData.subscriptionType);
    }
  }, [profile.SubscriptionData]);

  const [promoLength, setPromoLength] = useState(0);
  useEffect(() => {
    if (profile && profile.SubscriptionData) {
      setPromoLength(
        timeSpan(
          profile.SubscriptionData!.startTimeMillis,
          profile.SubscriptionData!.renewalTimeMillis,
          "days"
        )
      );
    }
  }, [profile]);

  useEffect(() => {
    setTimeout(loadingComplete, 300);
  });

  return (
    <div
      className="app-body center payment-page payment-confirmation-page"
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className="component-box with-header"
        style={
          !isMobile
            ? { backgroundColor: componentBackgroundColor }
            : { backgroundColor: "transparent" }
        }
      >
        <div className="component-box-header">
          <FlowIndicator
            stepName={"Download"}
            step={flowIndicatorStep}
            stepCount={4}
          />
          <h1 className="font-regular">Thanks</h1>
        </div>
        <h1 className="text-center top-title">
          Your {promoLength} day free code has been activated.
        </h1>
        <p className="text-center m-1 mt-2">
          Download the app and login to start enjoying calmer day times and
          easier bedtimes.
        </p>
        <Download />
        {subscriptionType && subscriptionType !== "promo" && (
          <React.Fragment>
            <p className="text-center m-2 mb-1">
              We have emailed you a confirmation email at:
            </p>
            <p
              className="email-text"
              style={{ backgroundColor: emailBackgroundColor }}
            >
              {email}
            </p>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default withConfirmAnimation(CodeConfirmation, 4);
