import React, { useEffect, useState } from "react";

import { Labels } from "../../store/promotion";

import { Helmet } from "react-helmet";
import { AllowIndexURIs } from "./data/constants";

import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";

import { events } from "../../helpers/logger";
import { computeLabels } from "../../helpers/promotionLabels";

import Promotion from "../promotion/Promotion";
import TryMoshi from "./components/tryMoshi/TryMoshi";
import Questions from "./components/questions/Questions";
import TrustBox from "../../../shared/components/Trustbox";
import Reviews from "./components/reviews/Reviews";

import "./LandingPage.scss";

interface Props {
  className?: string;
}

const LandingPage = ({ className = "landing-page" }: Props) => {
  const location = useLocation();
  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const trialUsed = useSelector((state: ApplicationState) => {
    if (state.profile.SubscriptionData) {
      return state.profile.SubscriptionData.trialUsed;
    }
    return false;
  });

  const [promotionClass, setPromotionClass] = useState("");
  useEffect(() => {
    if (promotion.data) {
      setPromotionClass(`promotion-${promotion.data.promotionSlug}`);
    }
  }, [promotion]);

  const [showFreeTrial, setShowFreeTrial] = useState(true);
  useEffect(() => {
    if (promotion.data) {
      setShowFreeTrial(promotion.data.withTrial && !trialUsed);
    }
  }, [promotion, trialUsed, showFreeTrial]);

  const [labels, setLabels] = useState<Labels>();
  useEffect(() => {
    if (promotion.data) {
      setLabels(
        computeLabels(promotion.data.labels, showFreeTrial, promotion.referral)
      );
    }
  }, [promotion, showFreeTrial]);

  const promotionClick = (position: string) => {
    return () => {
      events.promotion.click(promotion, position);
    };
  };

  return (
    <React.Fragment>
      <Helmet>
        {!AllowIndexURIs.includes(location.pathname) && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <div className={"app-body " + className + " " + promotionClass}>
        <Promotion showFreeTrial={showFreeTrial} />
        <TryMoshi
          labels={labels}
          showFreeTrial={showFreeTrial}
          onClick={promotionClick}
        />
        <Reviews
          labels={labels}
          showFreeTrial={showFreeTrial}
          onClick={promotionClick}
        />
        <TrustBox />
        <Questions showFreeTrial={showFreeTrial} />
        <div className="bottom-padding" />
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
