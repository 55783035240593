import React from "react";

import "./StoryDetails.scss";

import favoriteActiveIcon from "../images/Icon_Active_Heart@1_5x.svg";
import favoriteInactiveIcon from "../images/Heart@1_5x.svg";

const StoryDetails = ({
  category,
  name,
  narrator,
  description,
  artist,
  isFavorite,
  onFavorite,
}: {
  category: string;
  name: string;
  narrator?: string;
  description?: string;
  artist: string;
  isFavorite: boolean;
  onFavorite: () => void;
}) => {
  return (
    <div className="details">
      <p className="category">{category}</p>
      <p className="title">{name}</p>
      {narrator && <p className="narrator">Told by {narrator}</p>}
      {description && <p className="description">{description}</p>}
      <p className="artist">{artist}</p>
      <div
        className={`favorite ${isFavorite ? "active" : ""}`}
        id="add-to-favorites-button"
        onClick={onFavorite}
      >
        <img
          className="favorite-heart"
          src={isFavorite ? favoriteActiveIcon : favoriteInactiveIcon}
          alt={isFavorite ? "favorite active" : "favorite inactive"}
        />
      </div>
    </div>
  );
};
export default StoryDetails;
