import React from "react";
import "./SelectButton.scss";

import tickIcon from "../images/tick.svg";

interface Props<T> {
  title: string;
  badgeText: string;
  body: string;
  selected: boolean;
  value: T;
  onClick: (value: T) => void;
}

const SelectButton = <T,>({
  title,
  badgeText,
  body,
  selected,
  value,
  onClick,
}: Props<T>) => {
  const onSelect = () => {
    onClick(value);
  };
  return (
    <div className="select-button-wrapper">
      {badgeText && (
        <div className="badge">
          <p>{badgeText}</p>
        </div>
      )}
      <div
        className={`select-button + ${selected ? "selected" : ""}`}
        onClick={onSelect}
      >
        <div className="select-button-content">
          <div className="select-button-text">
            <h3 className="title">{title}</h3>
            <div className="body">{body}</div>
          </div>
          <img
            className={`select-button-selected-icon + ${
              selected ? "selected" : ""
            }`}
            src={tickIcon}
            alt="Tick"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectButton;
