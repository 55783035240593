import React, { useMemo } from "react";
import { Form, Field, FormikBag, FormikProps, withFormik } from "formik";
import CountryList from "react-select-country-list";
import {
  ClientDynamoUser,
  ISchoolSettingsUpdate,
  putCurrentSchoolUserSettingsSchema,
} from "shared/store/user/types";
import FieldError from "../elements/fieldError";
import LoadingIndicator from "../../loading/LoadingIndicator";
import FormDropdown from "../elements/FormDropdown";
import { ValidationError } from "../../../helpers/errors";

interface FormProps {
  editMode: boolean;
  switchMode: () => void;
  userDataUpdate: (u: ISchoolSettingsUpdate) => Promise<void>;
  userData: ClientDynamoUser;
}

const SchoolSettingsTemplate = (
  props: FormikProps<ISchoolSettingsUpdate> & FormProps
) => {
  const countryList = useMemo(() => CountryList().getData(), []);
  const schoolTypes = useMemo(
    () => [
      { id: "private", label: "Private" },
      { id: "public", label: "Public" },
      { id: "charter", label: "Charter" },
    ],
    []
  );
  const { values, errors, touched, editMode, switchMode, isSubmitting } = props;

  const cancel = () => {
    props.resetForm();
    switchMode();
  };

  return (
    <Form className="content">
      <h1 className="text-center">School settings</h1>
      <div className="form-group">
        {errors.schoolName && touched.schoolName && (
          <FieldError message={errors.schoolName} />
        )}
        <Field
          id="schoolName"
          name="schoolName"
          type="text"
          className={
            errors.schoolName && touched.schoolName
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={50}
          placeholder="School name"
          disabled={!editMode}
        />
      </div>
      <div className="form-group">
        {errors.schoolUrl && touched.schoolUrl && (
          <FieldError message={errors.schoolUrl} />
        )}
        <Field
          id="schoolUrl"
          name="schoolUrl"
          type="text"
          className={
            errors.schoolUrl && touched.schoolUrl
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={50}
          placeholder="School website"
          disabled={!editMode}
        />
      </div>
      <div className="form-group">
        {errors.schoolType && touched.schoolType && (
          <FieldError message={errors.schoolType} />
        )}
        <FormDropdown
          id={"schoolType"}
          name={"schoolType"}
          noSelection={"Select a school Type"}
          startingValue={values.schoolType as string}
          options={schoolTypes}
          disabled={!editMode}
        />
      </div>
      <div className="form-group">
        {errors.city && touched.city && <FieldError message={errors.city} />}
        <Field
          id="city"
          name="city"
          type="text"
          className={
            errors.city && touched.city
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={50}
          placeholder="City"
          disabled={!editMode}
        />
      </div>
      <div className="form-group">
        {errors.state && touched.state && <FieldError message={errors.state} />}
        <Field
          id="state"
          name="state"
          type="text"
          className={
            errors.state && touched.state
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={50}
          placeholder="State"
          disabled={!editMode}
        />
      </div>
      <div className="form-group">
        {errors.country && touched.country && (
          <FieldError message={errors.country} />
        )}
        <FormDropdown
          id={"country"}
          name={"country"}
          noSelection={"Select a country"}
          startingValue={values.country as string}
          options={countryList}
          disabled={!editMode}
        />
      </div>
      {!editMode && (
        <div className={`form-group with-loading-block`}>
          <div className="actions">
            <button
              className="btn action filled"
              type="button"
              onClick={switchMode}
            >
              Edit
            </button>
          </div>
        </div>
      )}
      {editMode && (
        <div className={`form-group with-loading-block`}>
          {(isSubmitting && <LoadingIndicator />) || (
            <div className="actions">
              <button className="btn action filled" type="submit">
                Save
              </button>
              <button
                className="btn secondary filled"
                type="button"
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

const SchoolSettingsForm = withFormik({
  displayName: "SchoolSettingsForm",
  handleSubmit: (
    values: ISchoolSettingsUpdate,
    formikBag: FormikBag<FormProps, ISchoolSettingsUpdate>
  ) => {
    formikBag.props
      .userDataUpdate(values)
      .then(() => {
        formikBag.setSubmitting(false);
        formikBag.props.switchMode();
      })
      .catch((e) => {
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: (props: FormProps) => ({
    schoolName: props.userData.schoolDetails?.schoolName,
    schoolUrl: props.userData.schoolDetails?.schoolUrl.trim(),
    schoolType: props.userData.schoolDetails?.schoolType,
    city: props.userData.schoolDetails?.city,
    state: props.userData.schoolDetails?.state || "",
    country: props.userData.schoolDetails?.country,
  }),
  validationSchema: putCurrentSchoolUserSettingsSchema,
})(SchoolSettingsTemplate);

export default SchoolSettingsForm;
